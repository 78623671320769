/** @format */

import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const Conatainer = styled.div`
  border-radius: 1.5625rem;
  background: #f7f7f7;
  overflow: hidden;
  min-height: 33rem;
  max-height: 33rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0.6rem;
  filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
  .ImgContainer {
    position: relative;
    .new {
      text-align: center;
      position: absolute;
      top: 2%;
      left: 2%;
      border-radius: 1.5625rem;
      background: rgba(255, 255, 255, 0.65);
      backdrop-filter: blur(1px);
      padding: 0.25rem 0.5rem;
      color: #0061df;
      z-index: 1;
    }
    .Favourite {
      position: absolute;
      top: 2%;
      right: 2%;
      border-radius: 1.5625rem;
      background: rgba(255, 255, 255, 0.65);
      backdrop-filter: blur(1px);
      padding: 0.25rem;
      z-index: 1;
    }
    .slick-slider {
      .slick-dots {
        li {
          width: 7px;
          margin: 0 2px;
          button::before {
            color: #eef0f4;
            opacity: 1;
          }
          &.slick-active {
            button::before {
              color: #0061df;
            }
          }
        }
      }
      .slick-prev {
        top: 50%;
        left: 7px;
        z-index: 10;
        #card-arrows {
          fill: #0061df;
        }
      }
      .slick-next {
        top: 50%;
        right: 7px;
        #card-arrows {
          fill: #0061df;
        }
      }
      .slick-dots {
        bottom: 13px;
      }
      .slick-track {
        padding: 0;
      }
      .slick-list {
        max-height: 18rem;
        .slick-track {
          .slick-slide {
            overflow: hidden;
            img {
              min-height: 18rem;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    p {
      margin: 0;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  .share {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .wrapperShare {
      filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
    }
  }
  .homeInfo {
    h1 {
      font-size: clamp(1rem, 1rem + 2vw, 2rem);
    }
  }
`;

export const SearchIcon = styled(Link)`
  position: relative;
  z-index: 100;
  width: 4.375rem;
  height: 4.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 100%;
  margin-top: -8rem;
  margin-bottom: 1rem;
  filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
`;

export const HomeInsideInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d9d9d9;
  margin: 1rem;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
  .bed,
  .bath,
  .garage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
  }
`;
