import { createContext, useContext, useReducer, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const AuthContext = createContext(null);

const SECRET_KEY = 'aGVsbG9fd29ybGRfZ2VuZXJhdGVfa2V5IQ==';

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error('Failed to decrypt localStorage data', error);
    return null;
  }
};

const initialState = {
  Admin: { isAdmin: false },
};

const initializer = () => {
  const savedState = localStorage.getItem('myAuthContext');
  return savedState ? decryptData(savedState) || initialState : initialState;
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'set_admin':
      return { ...state, Admin: action.payload.Admin };
    default:
      return state;
  }
};

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState, initializer);

  useEffect(() => {
    try {
      const encryptedState = encryptData(state);
      localStorage.setItem('myAuthContext', encryptedState);
    } catch (error) {
      console.error('Failed to encrypt and save state', error);
    }
  }, [state]);

  const setAdmin = (Admin) => {
    dispatch({ type: 'set_admin', payload: { Admin } });
  };

  return (
    <AuthContext.Provider value={{ state, setAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
};

export default AuthContextProvider;
