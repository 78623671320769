import './App.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar.component';
import Home from './page/home.page';
import NewConstructionl from './page/NewConstructionl.page';
import HomeDetail from './page/HomeDetail.page';
import Detailpage from './page/Detailpage';
import NewProperties from './page/NewProperties';
import ScrollToTop from './page/ScrollToTop';
import Search from './components/Search/Search';
import MapSearch from './components/MapSearch/MapSearch';
import Login from './components/LoginPage/Login';
import Register from './components/LoginPage/Register';
import DashboardRoute from './routes/DashboardRoute';
import SellerDashboardRoute from './routes/SellerDashboardRoute';
import BuyerDashboardRoute from './routes/BuyerDashboardRoute';
import Builders from './components/Builders/Builders';
import BuilderDetails from './components/BuilderDetails/BuilderDetails';
import AssignmentSaleDetail from './components/DetailPages/Components/AssignmentSaleDetail';
import InventorySaleDetail from './components/DetailPages/Components/InventorySaleDetail';
import PreConstriuctionSaleDetail from './components/DetailPages/Components/PreConstriuctionSaleDetail';
import SellerPropertyMain from './components/SellerPropertyMain/SellerPropertyMain';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="new-construction" element={<NewConstructionl />} />
          <Route path="new-construction/:id" element={<HomeDetail />} />
          <Route path="featured-homes/:id" element={<HomeDetail />} />
          <Route path="properties/:id" element={<Detailpage />} />
          <Route path="new-properties/:id" element={<NewProperties />} />
          <Route path="search" element={<Search />} />
          <Route path="map-search" element={<MapSearch />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Register />} />
          <Route path="dashboard/builder/*" element={<DashboardRoute />} />
          <Route path="dashboard/seller/*" element={<SellerDashboardRoute />} />
          <Route path="dashboard/agent/*" element={<BuyerDashboardRoute />} />
          <Route path="dashboard/admin/*" element={<AdminDashboard />} />
          <Route path="builders" element={<Builders />} />
          <Route path="/builder-detail/:id" element={<BuilderDetails />} />
          <Route path="assignment" element={<AssignmentSaleDetail />} />
          <Route path="inventory" element={<InventorySaleDetail />} />
          <Route
            path="preconstruction"
            element={<PreConstriuctionSaleDetail />}
          />
          <Route path="sellerproperties/:id" element={<SellerPropertyMain />} />
        </Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
