import './citygrid.css';

import mississaugaImage from './Image/pexels-yusuf-sh-3079964-14071149.jpg';
import torontoImage from './Image/temp3_1_sec9_2.png';
import ottawaImage from './Image/temp3_1_sec9_3.png';
import dummyImage from './Image/temp3_2_sec2.jpeg';
import bramptonImage from './Image/temp3_1_sec9_4.png';

function CityGrid() {
  return (
    <>
      <div className="city-grid-container">
        <h1 style={{ fontFamily: 'Tahoma ,sans-serif', fontWeight: 'bold' }}>
          Find Properties in These Cities
        </h1>
        <p style={{ fontFamily: 'Tahoma (sans-serif)' }}>
          Take a deep dive and browse homes for sale, original neighborhood
          photos, resident reviews, and local insights to find what is right for
          you.
        </p>
        <div className="city-grid">
          {/* First card (Mississauga) */}
          <div
            className="city-card first"
            style={{ backgroundImage: `url(${mississaugaImage})` }}
          >
            <div className="city-overlay">
              <h2 className="city-name">Mississauga</h2>
            </div>
          </div>

          {/* Second card (Toronto, spanning 2 columns) */}
          <div
            className="city-card second"
            style={{ backgroundImage: `url(${torontoImage})` }}
          >
            <div className="city-overlay">
              <h2 className="city-name">Toronto</h2>
            </div>
          </div>

          {/* Review card below Toronto */}
          <div
            className="city-card review"
            style={{ backgroundColor: '#B8B8B8' }}
          >
            <div className="city-overlay">
              <h2 className="city-name">Review</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
          </div>

          {/* Ottawa card */}
          <div
            className="city-card"
            style={{ backgroundImage: `url(${ottawaImage})`, height: '232px' }}
          >
            <div className="city-overlay">
              <h2 className="city-name">Ottawa</h2>
            </div>
          </div>

          {/* Dummy card */}
          <div
            className="city-card dummy"
            style={{ backgroundImage: `url(${dummyImage})` }}
          >
            <div className="city-overlay">
              <h2 className="city-name">Dummy</h2>
            </div>
          </div>

          {/* Review card */}
          <div className="city-card review-card">
            <div className="city-overlay">
              <h2 className="city-name">Review</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
          </div>

          {/* Brampton card (spanning 2 columns) */}
          <div
            className="city-card brampton"
            style={{ backgroundImage: `url(${bramptonImage})` }}
          >
            <div className="city-overlay">
              <h2 className="city-name">Brampton</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CityGrid;
