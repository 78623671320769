import { setImageBanner } from './setImageBanner';
export const DifferentModel = (Data) => {
  let Images = [];
  const setImages = (value) => {
    Images = value;
  };
  setImageBanner(Data, setImages);
  const priceFormat = (stringValue) => {
    let value = parseFloat(stringValue);
    return value.toLocaleString('en-CA', {
      style: 'currency',
      currency: 'CAD',
    });
  };
  return Data.diffrent_floor_plans
    ?.filter((item) => !item?.is_parent)
    .map((item) => {
      return {
        ...item,
        img: Images[0].file_url,
        list_price: item?.list_price ? priceFormat(item.list_price) : '',
      };
    });
};
