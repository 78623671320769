import React, { useState, useEffect } from 'react';
import '../BuyerDaashboard/buyerdashboard.css';
import { Link, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { customAxios } from '../../config/axios';

function BuyerDashboard() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [user, setUser] = useState({ name: '', profileImage: '' });

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // Get token from cookies
        const token = Cookies.get('token'); // This fetches the token from cookies
        if (!token) {
          console.log('No token provided');
          return;
        }

        const response = await customAxios.get('/api/get-userdetails', {
          headers: { 'x-access-token': token },
        });

        if (response.data.status) {
          setUser({
            name: response.data.data.name,
            profileImage: response.data.data.profileImage
            ? response.data.data.profileImage.replace('/uploads/', '') 
            : 'default-profile-img-url',
          });
        } else {
          console.log('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching user details', error);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div className="buyer-DashBoard_Container d-flex gap-2">
      <aside className={`buyer-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="buyer-Links">
          <button className="buyer-toggle-btn" onClick={toggleSidebar}>
            ☰
          </button>

          <div className="buyer-profile">
            <img
              src={user.profileImage} // Dynamically set profile image
              alt="profile"
              className="buyer-profile-image"
            />
            {!isCollapsed && (
              <p style={{ fontWeight: 'bold' }} className="buyer-user-name">
                {user.name}
              </p>
            )}{' '}
            {/* Dynamically set name */}
          </div>

          <Link to={'buyerprofile'} className="buyer-menu-item">
            <svg
              className="buyer-menu-icon"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                clip-rule="evenodd"
              />
            </svg>

            <p className="buyer-menu-text">Profile</p>
          </Link>

          <Link to={'saved-properties'} className="buyer-menu-item">
            <svg
              className="buyer-menu-icon"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M7.833 2c-.507 0-.98.216-1.318.576A1.92 1.92 0 0 0 6 3.89V21a1 1 0 0 0 1.625.78L12 18.28l4.375 3.5A1 1 0 0 0 18 21V3.889c0-.481-.178-.954-.515-1.313A1.808 1.808 0 0 0 16.167 2H7.833Z" />
            </svg>

            <p className="buyer-menu-text">Saved Builders</p>
          </Link>

          {/* <Link to={"builder-lead"} className="buyer-menu-item">
            <svg
              className="buyer-menu-icon"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
            </svg>

            <p className="buyer-menu-text">Favorites</p>
          </Link>

          <Link to={"seller-info"} className="buyer-menu-item">
            <svg
              className="buyer-menu-icon"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                clip-rule="evenodd"
              />
            </svg>

            <p className="buyer-menu-text">Saved Sellers</p>
          </Link> */}
        </div>
      </aside>
      <div className="buyer-View">
        <Outlet />
      </div>
    </div>
  );
}

export default BuyerDashboard;
