import React, { useContext } from 'react';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NewConstructionContext } from '../../Context/Construction.context';

export default function Peginate({
  forcePage,
  onPageChange,
  pageCount,
  pageRangeDisplayed = 5,
}) {
  const { SetPage } = useContext(NewConstructionContext);
  const handlePageChange = (pageIndex) => {
    const UpdatedValue = pageIndex.selected + 1;
    console.log(pageIndex.selected + 1);
    SetPage(UpdatedValue);
    window.scrollTo(0, 0);
  };
  return (
    <ReactPaginate
      breakLabel="..."
      forcePage={forcePage - 1}
      nextLabel="Next >"
      onPageChange={handlePageChange}
      pageRangeDisplayed={pageRangeDisplayed}
      pageCount={pageCount}
      previousLabel="< Previous"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination d-flex justify-content-center flex-wrap"
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
}
