import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import '../SellerDashboard/sellerdashboard.css';
import Cookies from 'js-cookie';
import { customAxios } from '../../config/axios';

function SellerDashboard() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  // const [activeMenu, setActiveMenu] = useState("Dashboard");
  const [user, setUser] = useState({ name: '', profileImage: '' });

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // Get token from cookies
        const token = Cookies.get('token'); // This fetches the token from cookies
        if (!token) {
          console.log('No token provided');
          return;
        }

        const response = await customAxios.get('/api/get-userdetails', {
          headers: { 'x-access-token': token },
        });

        if (response.data.status) {
          setUser({
            name: response.data.data.name,
            profileImage:
              response.data.data.profileImage || 'default-profile-img-url',
          });
        } else {
          console.log('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching user details', error);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div className="seller-DashBoard_Container d-flex gap-2">
      <aside className={`seller-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="seller-Links">
          <button className="seller-toggle-btn" onClick={toggleSidebar}>
            ☰
          </button>

          <div className="seller-profile">
            <img
              src={user.profileImage} // Dynamically set profile image
              alt="profile"
              className="buyer-profile-image"
            />
            {!isCollapsed && (
              <p style={{ fontWeight: 'bold' }} className="buyer-user-name">
                {user.name}
              </p>
            )}{' '}
            {/* Dynamically set name */}
          </div>
          <Link to={'seller-details'} className="seller-menu-item">
            <svg
              className="seller-menu-icon"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                clip-rule="evenodd"
              />
            </svg>

            <p className="seller-menu-text">Profile</p>
          </Link>
          <Link to={'seller-properties'} className="seller-menu-item">
            <svg
              className="seller-menu-icon"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M4 4a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2v14a1 1 0 1 1 0 2H5a1 1 0 1 1 0-2V5a1 1 0 0 1-1-1Zm5 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-1Zm-5 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm-3 4a2 2 0 0 0-2 2v3h2v-3h2v3h2v-3a2 2 0 0 0-2-2h-2Z"
                clip-rule="evenodd"
              />
            </svg>

            <p className="seller-menu-text">Properties</p>
          </Link>
          <Link to={'seller-lead'} className="seller-menu-item">
            <svg
              className="seller-menu-icon"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M17 6h-2V5h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2h-.541A5.965 5.965 0 0 1 14 10v4a1 1 0 1 1-2 0v-4c0-2.206-1.794-4-4-4-.075 0-.148.012-.22.028C7.686 6.022 7.596 6 7.5 6A4.505 4.505 0 0 0 3 10.5V16a1 1 0 0 0 1 1h7v3a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-3h5a1 1 0 0 0 1-1v-6c0-2.206-1.794-4-4-4Zm-9 8.5H7a1 1 0 1 1 0-2h1a1 1 0 1 1 0 2Z" />
            </svg>

            <p className="seller-menu-text">Leads</p>
          </Link>
          <Link to={'seller-dealers'} className="seller-menu-item">
            <svg
              className="seller-menu-icon"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                clip-rule="evenodd"
              />
            </svg>

            <p className="seller-menu-text">Dealers</p>
          </Link>
        </div>
      </aside>
      <div className="seller-View">
        <Outlet />
      </div>
    </div>
  );
}

export default SellerDashboard;
