/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  width: auto;
  margin: 0 auto;
  margin-top: 6rem;
  border-top: 1px solid #6e6e73;
  padding-top: 1rem;
`;
export const FooterLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
  background-color: #f9f9f9;

  > div {
    flex: 1 1 calc(50% - 1.5rem); // Allows for two equal columns with spacing
    max-width: calc(50% - 1.5rem); // Ensures consistent width
  }

  p {
    color: #6e6e73;
    font-family: Tahoma;
    font-size: 1rem;
    font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
    font-style: normal;
    line-height: normal;
    margin-bottom: 1rem;
  }
`;

export const FooterLink = styled(Link)`
  display: block;
  color: #6e6e73;
  font-family: Inter;
  font-size: 1rem;
  font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  padding-bottom: 1rem;
`;
export const FooterNlink = styled(Link)`
  display: block;
  color: #0061df;
  font-family: Inter;
  font-size: 1rem;
  font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
  font-weight: 500;
  padding-bottom: 1rem;
`;

export const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-radius: 0.25rem;
  background: #0061df;
  padding: 0.63rem 1.25rem;
  p {
    color: #fff;
    text-transform: capitalize;
    margin: 0;
    margin-left: 0.4rem;
  }
`;
export const ContactUs = styled.div`
  background-color: #2e3d5e;
  height: 3rem;
  padding: 0.5rem;
  display: flex;
  align-items: center; // Vertically center the content
  justify-content: space-between; // Distribute items across the available space
  width: 100%;

  h4 {
    color: white;
  }

  .contact {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .socialMedia {
    font-weight: 700;
    color: white;
  }

  .name {
    font-weight: 400;
    color: #6e6e73;
    text-align: center; // Center the name text
    flex: 1; // Allow this to expand and take up space for centering
  }

  .end {
    color: white;
    font-weight: 400;
    text-align: right;
    margin-right: 1rem;
    margin-top: 0.5rem;
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;
