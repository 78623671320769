import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './sellerpropertyupdate.css';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { customAxios } from '../../../../config/axios';

const ItemTypes = {
  IMAGE: 'image',
};

// ImageItem Component for draggable images
const ImageItem = ({ index, image, images, setImages }) => {
  const [, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    hover: (item) => {
      if (item.index !== index) {
        const newImages = [...images];
        newImages.splice(index, 0, newImages.splice(item.index, 1)[0]);
        setImages(newImages);
        item.index = index;
      }
    },
  });

  return (
    <div ref={(node) => drag(drop(node))} className="image-preview-item">
      <img
        src={URL.createObjectURL(image)}
        alt={`Preview ${index}`}
        className="image-preview-thumbnail"
      />
      <button
        onClick={() => removeImage(index, images, setImages)}
        className="remove-image-button"
      >
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
          />
        </svg>
      </button>
    </div>
  );
};

const removeImage = (index, images, setImages) => {
  const newImages = [...images];
  newImages.splice(index, 1);
  setImages(newImages);
};

function SellerPropertyUpdate() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    typeofsale: '',
    buildername: '',
    city: '',
    completionyear: '',
    projectdescription: '',
    housetype: '',
    price: '',
    bed: '',
    squarefeet: '',
    parking: '',
    unitdescription: '',
    modelname: '',
    projectname: '',
    projectaddress: '',
    community: '',
    builderaddress: '',
    builderdescription: '',
    communitydescription: '',
    housestyle: '',
    pricepersqft: '',
    bath: '',
    garage: '',
    lotsize: '',
    amenities: [],
    propertyImages: [],
    floorPlanImages: [],
  });

  const [floorPlanImages, setFloorPlanImages] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get(
          `/seller/viewbyid-sellerprperty/${id}`,
          {
            headers: { 'x-access-token': token },
          }
        );
        setFormData(response.data.data);
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    };
    fetchProperty();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e, key) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formData, [key]: files });
  };

  const handleAmenityChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      const amenities = checked
        ? [...prevData.amenities, value]
        : prevData.amenities.filter((amenity) => amenity !== value);
      return { ...prevData, amenities };
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get('token');
      const updatedFormData = {
        ...formData,
        floorPlanImages,
        propertyImages,
      };
      const response = await customAxios.put(
        `/seller/update-sellerproperty/${id}`,
        updatedFormData,
        {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success('Property updated successfully!');
    } catch (error) {
      console.error('Error updating property:', error);
    }
    navigate('/dashboard/seller/seller-properties'); // Corrected path
  };

  const handleFloorPlanImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setFloorPlanImages((prevImages) => [...prevImages, ...files]);
  };

  const handlePropertyImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setPropertyImages((prevImages) => [...prevImages, ...files]);
  };

  const renderImagePreviews = (images, setImages) => {
    return images.map((image, index) => (
      <ImageItem
        key={index}
        index={index}
        image={image}
        images={images}
        setImages={setImages}
      />
    ));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="background">
        <div className="common-form-container">
          <h1 className="common-form-title">Property Details Form</h1>
          <form className="common-form" onSubmit={handleSubmit}>
            <div className="common-form-row">
              <div className="common-form-group">
                <label>Type Of Sale</label>
                <select
                  name="typeofsale"
                  value={formData.typeofsale}
                  onChange={handleChange}
                >
                  <option value="private">Private Sale</option>
                  <option value="assignment">Assignment Sale</option>
                </select>
              </div>

              <div className="common-form-group">
                <label>Model Name:</label>
                <input
                  type="text"
                  name="modelname"
                  required
                  value={formData.modelname}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Builder Name:</label>
                <input
                  type="text"
                  name="buildername"
                  required
                  value={formData.buildername}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Project Name:</label>
                <input
                  type="text"
                  name="projectname"
                  required
                  value={formData.projectname}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="common-form-row">
              <div className="common-form-group">
                <label>City:</label>
                <input
                  type="text"
                  name="city"
                  required
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Community (optional):</label>
                <input
                  type="text"
                  name="community"
                  value={formData.community}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Project Address:</label>
                <input
                  type="text"
                  name="projectaddress"
                  required
                  value={formData.projectaddress}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Builder Address (optional):</label>
                <input
                  type="text"
                  name="builderaddress"
                  value={formData.builderaddress}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="common-form-row">
              <div className="common-form-group">
                <label>Completion Year:</label>
                <input
                  type="number"
                  name="completionyear"
                  required
                  value={formData.completionyear}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Builder Description:</label>
                <textarea
                  name="builderdescription"
                  value={formData.builderdescription}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Project Description:</label>
                <textarea
                  name="projectdescription"
                  value={formData.projectdescription}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="common-form-row">
              <div className="common-form-group">
                <label>Community Description:</label>
                <textarea
                  name="communitydescription"
                  value={formData.communitydescription}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="common-form-row">
              <div className="common-form-group">
                <label>House Type:</label>
                <select
                  name="housetype"
                  value={formData.housetype}
                  onChange={handleChange}
                >
                  <option value="detached">Detached</option>
                  <option value="semi-detached">Semi-detached</option>
                  <option value="town-house">Town House</option>
                  <option value="condo-townhouse">Condo-townhouse</option>
                  <option value="condo-apartment">Condo apartment</option>
                </select>
              </div>
              <div className="common-form-group">
                <label>House Style:</label>
                <select
                  name="housestyle"
                  value={formData.housestyle}
                  onChange={handleChange}
                >
                  <option value="multi-story">Multi Story</option>
                  <option value="three-story">3 Story</option>
                  <option value="two-story">2 Story</option>
                  <option value="bungalow">Bungalow</option>
                  <option value="split-level">Split Level</option>
                </select>
              </div>
              <div className="common-form-group">
                <label>Price:</label>
                <input
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Price per SqFt:</label>
                <input
                  type="number"
                  name="pricepersqft"
                  value={formData.pricepersqft}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="common-form-row">
              <div className="common-form-group">
                <label>Bed:</label>
                <input
                  type="number"
                  name="bed"
                  value={formData.bed}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Bath:</label>
                <input
                  type="number"
                  name="bath"
                  value={formData.bath}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Square Feet:</label>
                <input
                  type="number"
                  name="squarefeet"
                  value={formData.squarefeet}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Garage:</label>
                <input
                  type="number"
                  name="garage"
                  value={formData.garage}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Parking (for condo apartments):</label>
                <input
                  type="text"
                  name="parking"
                  value={formData.parking}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Lot Size (for freehold):</label>
                <input
                  type="text"
                  name="lotsize"
                  value={formData.lotsize}
                  onChange={handleChange}
                />
              </div>
              <div className="common-form-group">
                <label>Unit Description:</label>
                <textarea
                  name="unitdescription"
                  value={formData.unitdescription}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="common-form-row">
              <div className="common-form-group">
                <label className="common-form-label">Amenities</label>
                <div
                  className="amenities-options"
                  style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}
                >
                  {[
                    'Gym',
                    'Swimming Pool',
                    'Parking',
                    'Security',
                    'Garden',
                    'Playground',
                    'Hospital',
                    'Mall',
                    'Clubhouse',
                    'Restaurants',
                  ].map((amenity) => (
                    <div
                      key={amenity}
                      className="checkbox-item"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <input
                        type="checkbox"
                        id={amenity}
                        name="amenities"
                        value={amenity}
                        checked={formData.amenities.includes(amenity)}
                        onChange={handleAmenityChange}
                        className="common-form-input-checkbox"
                      />
                      <label htmlFor={amenity}>{amenity}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="common-form-row">
              <div className="common-form-group">
                <label>Floor Plan Image:</label>
                <input
                  type="file"
                  accept="image/*"
                  style={{ backgroundColor: 'white' }}
                  multiple
                  onChange={handleFloorPlanImagesChange}
                />
                <div className="image-preview">
                  {renderImagePreviews(floorPlanImages, setFloorPlanImages)}
                </div>
              </div>

              <div className="common-form-group">
                <label>Property Image:</label>
                <input
                  type="file"
                  accept="image/*"
                  style={{ backgroundColor: 'white' }}
                  multiple
                  onChange={handlePropertyImagesChange}
                />
                <div className="image-preview">
                  {renderImagePreviews(propertyImages, setPropertyImages)}
                </div>
              </div>
            </div>

            <button type="submit" className="common-form-submit-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </DndProvider>
  );
}

export default SellerPropertyUpdate;
