import React from 'react';
import '../RecommentedHomes/recommentedhomes.css';

function RecommentedHome() {
  const homes = [
    {
      image:
        'https://images.pexels.com/photos/164558/pexels-photo-164558.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', // Replace with actual image paths
      category: 'Apartment',
      date: 'March 19, 2024',
      title: 'Housing Markets That Changed the Most This Week',
      price: '1452$',
      name: 'Oakland, CA 94608',
    },
    {
      image:
        'https://images.pexels.com/photos/164558/pexels-photo-164558.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      category: 'Apartment',
      date: 'March 19, 2024',
      title: 'Read Unveils the Best Canadian Cities for Biking',
      name: 'Oakland, CA 94608s',
      price: '1452$',
    },
    {
      image:
        'https://images.pexels.com/photos/164558/pexels-photo-164558.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      category: 'Office',
      date: 'March 19, 2024',
      title: '10 Walkable Cities Where You Can Live Affordably',
      price: '1452$',
      name: 'Oakland, CA 94608',
    },
    {
      image:
        'https://images.pexels.com/photos/164558/pexels-photo-164558.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      category: 'Shop',
      date: 'March 19, 2024',
      title: 'New Apartment Nice in the Best Canadian Cities',
      price: '1452$',
      name: 'Oakland, CA 94608',
    },
  ];
  return (
    <>
      <div className="similar-homes">
        <h2>Similar Homes</h2>
        <div className="similar-homes-cards-container">
          {homes.map((home, index) => (
            <div
              className="similar-homes-card"
              key={index}
              style={{ backgroundImage: `url(${home.image})` }}
            >
              <div className="similar-homes-card-content">
                <h3 className="similar-homes-card-title">{home.title}</h3>
                <p className="similar-homes-card-category">
                  {home.category} • {home.date}
                </p>
                <p className="similar-homes-card-price"> Price :{home.price}</p>
                <h2 className="similar-homes-card-name">{home.name}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default RecommentedHome;
