import React, { useState, useEffect } from 'react';
import '../Styles/assignmentsaledetail.css';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { customAxios } from '../../../config/axios';

function AssignmentSaleDetail() {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 16;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const token = Cookies.get('token');
        if (!token) {
          console.log('No token found');
          return;
        }

        const response = await customAxios.get(
          '/seller/viewall-sellerproperty',
          {
            headers: { 'x-access-token': token },
          }
        );

        if (response.data.status) {
          const assignmentSaleProperties = response.data.data
            .filter((property) => property.typeofsale === 'Assignment Sale')
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

          setProperties(assignmentSaleProperties);
        } else {
          console.log('No properties found');
        }
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, []);

  // Pagination logic
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = properties.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  const nextPage = () => {
    if (currentPage < Math.ceil(properties.length / propertiesPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div>
        <h1 className="Assignmet-detail-h1">Assignment Sale</h1>
      </div>

      <div
        className="Assignmet-detail-container-flex"
        style={{ padding: '1rem' }}
      >
        <div className="row">
          {currentProperties.length === 0 ? (
            <div>No properties found</div>
          ) : (
            currentProperties.map((property, index) => (
              <div
                key={property._id || index}
                className="col-sm-6 col-lg-3 mt-4"
              >
                {/* Card component */}
                <Link
                  key={property._id}
                  to={`/sellerproperties/${property._id}`}
                  title={property.projectname}
                >
                  <div className="Assignmet-detail-altman-properties-item">
                    <a href="##" title={`Property ${index + 1}`} tabIndex="-1">
                      <div className="Assignmet-detail-altman-properties-item-inner-wrapper">
                        <div className="Assignmet-detail-altman-properties-item-photo">
                          <canvas
                            width="529"
                            height="460"
                            className="Assignmet-detail-lazy-load-canvas-element"
                            style={{
                              backgroundImage: `url(${property.propertyImages || 'default-image.jpg'})`,
                            }}
                          ></canvas>
                        </div>
                        <div className="Assignmet-detail-altman-properties-item-content-status-city search-ease">
                          <div>{property.status || 'N/A'}</div>
                          <div>{property.typeofsale || 'N/A'}</div>
                        </div>
                        <div className="Assignmet-detail-altman-properties-item-content search-ease">
                          <div className="Assignmet-detail-altman-properties-item-content-address">
                            {property.projectaddress || 'N/A'}
                            <div>{property.typeofsale || 'N/A'}</div>
                          </div>
                          <ul className="Assignmet-detail-altman-properties-item-content-features search-ease search-ps-0">
                            <li>{property.bed || 'N/A'} BD</li>
                            <li>{property.bath || 'N/A'} BA</li>
                            <li>{property.squarefeet || 'N/A'} sqft</li>
                          </ul>
                          <div className="Assignmet-detail-altman-properties-item-content-price search-ease">
                            ${property.price || 'N/A'}
                          </div>
                        </div>
                        <div className="Assignmet-detail-altman-properties-item-link-label search-ease">
                          View Details
                        </div>
                      </div>
                    </a>
                  </div>
                </Link>
                {/* Card component end */}
              </div>
            ))
          )}
        </div>
      </div>

      {properties.length > propertiesPerPage && (
        <div className="pagination">
          <button onClick={prevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>
            {' '}
            Page {currentPage} of{' '}
            {Math.ceil(properties.length / propertiesPerPage)}{' '}
          </span>
          <button
            onClick={nextPage}
            disabled={
              currentPage >= Math.ceil(properties.length / propertiesPerPage)
            }
          >
            Next
          </button>
        </div>
      )}
    </>
  );
}

export default AssignmentSaleDetail;
