import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import Cookies from 'js-cookie';

function DashboardMessage() {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const messages = [
    'Find your Perfect Homes, Building from BUILDER MATCH',
    'Sale Your Buildings, Property Through BUILDER MATCH',
  ];
  const [user, setUser] = useState({ name: '', profileImage: '' });

  useEffect(() => {
    const timer = setInterval(() => {
      setVisibleIndex((prev) => (prev + 1) % messages.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = Cookies.get('token');
        if (!token) {
          console.log('No token provided');
          return;
        }

        const response = await axios.get(
          'http://localhost:3002/api/get-userdetails',
          {
            headers: { 'x-access-token': token },
          }
        );

        if (response.data.status) {
          setUser({
            name: response.data.data.name,
            profileImage:
              response.data.data.profileImage || 'default-profile-img-url',
          });
        } else {
          console.log('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching user details', error);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div style={styles.container}>
      <h1>
        Hai <b>{user.name}</b>
      </h1>
      <h1>Welcome to the dashboard</h1>
      <p>Explore your dashboard ....</p>

      <motion.h3
        key={visibleIndex}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 1 }}
        style={styles.message}
      >
        {messages[visibleIndex]}
      </motion.h3>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  message: {
    marginTop: '20px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
};

export default DashboardMessage;
