import React from 'react';
import PropertyDetails from '../components/PropertyDetail/PropertyDetails.component';
import PropertiesMainPage from '../components/PropertiesMain/PropertiesMainPage';
export default function HomeDetail() {
  return (
    <>
      <PropertyDetails />
      <PropertiesMainPage />
    </>
  );
}
