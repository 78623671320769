import React from 'react';
import PropertiesMainPage from '../components/PropertiesMain/PropertiesMainPage';
import RecommentedHome from '../components/RecommentedHomes/RecommentedHome';

function Detailpage() {
  return (
    <>
      <PropertiesMainPage />
      <RecommentedHome />
    </>
  );
}

export default Detailpage;
