import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../InventorySale/inventorycard.css';
import Cookies from 'js-cookie';
import { customAxios } from '../../config/axios';

function InventoryCard() {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const token = Cookies.get('token');
        if (!token) {
          console.log('No token found');
          return;
        }

        const response = await customAxios.get(
          '/builderparent/get-allparentproperty',
          {
            headers: { 'x-access-token': token },
          }
        );

        if (response.data.status) {
          // Filter only Inventory Sale properties
          const inventoryProperties = response.data.data
            .filter((property) => property.typeofsale === 'Inventory Sale')
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 9); // Show latest 15

          setProperties(inventoryProperties);
        } else {
          console.log('No properties found');
        }
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, []);

  return (
    <>
      <div className="secton-1">
        <div style={{ marginTop: '2rem' }}>
          <h1 className="inv-h1">Inventory Sale - Hot Deals</h1>
          <p style={{ fontFamily: 'Tahoma, sans-serif', marginLeft: '1rem' }}>
            Best Projects of the Year
          </p>
          <div className="inv-view-btn-div">
            <button
              className="inv-view-btn"
              onClick={() => navigate('inventory')}
            >
              View More{' '}
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="inventory-cards">
          {properties.length > 0 ? (
            properties.map((property) => (
              <div key={property._id} className="altman-properties-item">
                <Link
                  to={`/properties/${property._id}`}
                  title={property.modelname}
                  tabIndex="-1"
                >
                  <div className="altman-properties-item-inner-wrapper">
                    <div className="altman-properties-item-photo">
                      <img
                        style={{ height: '25rem' }}
                        src={
                          property.propertyImages ||
                          'https://images.pexels.com/photos/5524164/pexels-photo-5524164.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                        }
                        alt={property.modelname}
                      />
                    </div>
                    <div className="altman-properties-item-content-status-city ease">
                      <div>{property.typeofsale}</div>
                      <div>{property.city}</div>
                    </div>
                    <div className="altman-properties-item-content ease">
                      <div className="altman-properties-item-content-address">
                        {property.projectname}
                        <div>{property.city}</div>
                      </div>
                      <ul className="altman-properties-item-content-features ease ps-0">
                        <li>7 BD</li>
                        <li>9 BA</li>
                        <li>14,000 sqft</li>
                      </ul>
                      <div className="altman-properties-item-content-price ease">
                        {property.projectname}
                      </div>
                    </div>
                    <div className="altman-properties-item-link-label ease">
                      View Details
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p className="no-properties">
              No Inventory Sale properties available.
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default InventoryCard;
