import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../LoginPage/login.css';
import register1 from '../LoginPage/Images/New entries-amico.svg';
import { toast } from 'react-toastify';
import { customAxios } from '../../config/axios';

function Register() {
  const [role, setRole] = useState('Builder');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: 'Builder',
    position: '',
    builderName: '',
    ownerConfirmation: 'false',
    brokerage: '',
    phone: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePassword = (password) => {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
      password
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      toast.error('Invalid email format');
      return;
    }

    if (!validatePassword(formData.password)) {
      toast.error(
        'Password must be at least 8 characters long and include at least one letter, one number, and one special character'
      );
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      const response = await customAxios.post('/api/register', formData);
      toast.success(response.data.message);
      setFormData({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 'Builder',
        position: '',
        builderName: '',
        ownerConfirmation: 'false',
        brokerage: '',
        phone: '',
      });
      navigate('/login');
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || 'Registration failed');
    }
  };

  const renderFormFields = () => {
    switch (role) {
      case 'Builder':
        return (
          <>
            <input
              type="text"
              name="builderName"
              placeholder="Company Name"
              required
              onChange={handleChange}
              value={formData.builderName}
            />
            <input
              type="text"
              name="position"
              placeholder="Position"
              required
              onChange={handleChange}
              value={formData.position}
            />
          </>
        );
      case 'Seller':
        return (
          <>
            <input
              type="text"
              name="brokerage"
              placeholder="Brokerage"
              required
              onChange={handleChange}
              value={formData.brokerage}
            />
          </>
        );
      case 'Agent':
        return (
          <>
            <input
              type="text"
              name="brokerage"
              placeholder="Brokerage"
              required
              onChange={handleChange}
              value={formData.brokerage}
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              required
              onChange={handleChange}
              value={formData.phone}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2>Register</h2>
        <div className="tabs">
          {['Builder', 'Seller', 'Agent'].map((roleType) => (
            <div
              key={roleType}
              className="tab"
              onClick={() => {
                setRole(roleType);
                setFormData({ ...formData, role: roleType });
              }}
              style={{
                cursor: 'pointer',
                backgroundColor: role === roleType ? '#ccc' : 'transparent',
              }}
            >
              {roleType}
            </div>
          ))}
        </div>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            required
            onChange={handleChange}
            value={formData.name}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            onChange={handleChange}
            value={formData.email}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            required
            onChange={handleChange}
            value={formData.password}
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            required
            onChange={handleChange}
            value={formData.confirmPassword}
          />

          {renderFormFields()}

          <button type="submit">Register</button>
        </form>
        <p className="register-link">
          Already have an account? <Link to="/login">Login here</Link>
        </p>
      </div>

      <div className="auth-image">
        <img src={register1} alt="Register Illustration" />
      </div>
    </div>
  );
}

export default Register;
