import React, { createContext, useReducer } from 'react';

const initialState = {
  min_price: '',
  max_price: '',
  home_type: '',
  building_status: '',
  city: '',
  bedroom: '',
  bathroom: '',
  sortBy: '',
  sortDir: '',
  paginate: '19',
  page: '1',
  search: '',
};

const ConstructionReducer = (state, action) => {
  switch (action.type) {
    case 'Min_Price':
      return { ...state, min_price: action.payload };
    case 'Max_Price':
      return { ...state, max_price: action.payload };
    case 'Home_Type':
      return { ...state, home_type: action.payload };
    case 'City':
      return { ...state, city: action.payload };
    case 'Bedroom':
      return { ...state, bedroom: action.payload };
    case 'Building_Status':
      return { ...state, building_status: action.payload };
    case 'Bathroom':
      return { ...state, bathroom: action.payload };
    case 'SortBy':
      return { ...state, sortBy: action.payload };
    case 'sort_dir':
      return { ...state, sortDir: action.payload };
    case 'peginate':
      return { ...state, paginate: action.payload };
    case 'page':
      return { ...state, page: action.payload };
    case 'searchCity':
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

const NewConstructionContext = createContext();

const NewConstructionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ConstructionReducer, initialState);
  const MinPrice = (price) => {
    dispatch({ type: 'Min_Price', payload: price });
  };
  const MaxPrice = (price) => {
    dispatch({ type: 'Max_Price', payload: price });
  };
  const HomeType = (HomeType) => {
    dispatch({ type: 'Home_Type', payload: HomeType });
  };
  const BuildingStatus = (PropertyType) => {
    dispatch({ type: 'Building_Status', payload: PropertyType });
  };
  const City = (city) => {
    dispatch({ type: 'City', payload: city });
  };
  const Bedroom = (bedroom) => {
    dispatch({ type: 'Bedroom', payload: bedroom });
  };
  const Bathroom = (bathroom) => {
    dispatch({ type: 'Bathroom', payload: bathroom });
  };
  const sortBy = (sortBy) => {
    dispatch({ type: 'SortBy', payload: sortBy });
  };
  const sortDir = (sortDir) => {
    dispatch({ type: 'sort_dir', payload: sortDir });
  };
  const paginate = (paginate) => {
    dispatch({ type: 'peginate', payload: paginate });
  };
  const SetPage = (page) => {
    dispatch({ type: 'page', payload: page });
  };
  const SearchCity = (city) => {
    dispatch({ type: 'searchCity', payload: city });
  };
  const Reset = () => {
    dispatch({ type: 'Min_Price', payload: '' });
    dispatch({ type: 'Max_Price', payload: '' });
    dispatch({ type: 'Home_Type', payload: '' });
    dispatch({ type: 'Building_Status', payload: '' });
    dispatch({ type: 'City', payload: '' });
    dispatch({ type: 'Bedroom', payload: '' });
    dispatch({ type: 'Bathroom', payload: '' });
    dispatch({ type: 'SortBy', payload: '' });
    dispatch({ type: 'page', payload: '1' });
    dispatch({ type: 'searchCity', payload: '' });
  };
  const contextValue = {
    state,
    MaxPrice,
    MinPrice,
    HomeType,
    City,
    Bedroom,
    Bathroom,
    sortBy,
    sortDir,
    paginate,
    SetPage,
    BuildingStatus,
    SearchCity,
    Reset,
  };
  return (
    <NewConstructionContext.Provider value={contextValue}>
      {children}
    </NewConstructionContext.Provider>
  );
};

export { NewConstructionContext, NewConstructionProvider };
