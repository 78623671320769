import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './buyerprofile.css';
import { toast } from 'react-toastify';
import { customAxios } from '../../../../config/axios';

function Buyerprofile() {
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    phone: '',
    location: '',
    profileImage: null,
    profileImagePreview: '',
    companyname: '',
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = Cookies.get('token');
        if (!token) return;

        const response = await customAxios.get('/api/get-userdetails', {
          headers: { 'x-access-token': token },
        });

        if (response.data.status) {
          const user = response.data.data;
          setFormData({
            email: user.email,
            fullName: user.name || '',
            phone: user.phone || '',
            location: user.location || '',
            companyname: user.companyname || '',
            profileImagePreview: user.profileImage || '', // Assuming image is stored as a URL
          });
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          profileImage: file,
          profileImagePreview: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = Cookies.get('token'); // Retrieve token from cookies
      if (!token) return;

      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.fullName);
      formDataToSend.append('phone', formData.phone);
      formDataToSend.append('location', formData.location);
      formDataToSend.append('companyname', formData.companyname);
      if (formData.profileImage) {
        formDataToSend.append('profileImage', formData.profileImage);
      }

      const response = await customAxios.put(
        '/api/update-user',
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        toast.success('Profile updated successfully!');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <>
      <h1 className="builder-h1">WELCOME BACK ...</h1>

      <div className="user-details-container">
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            <div className="upload-section">
              <div className="profile-container">
                {formData.profileImagePreview ? (
                  <img
                    src={formData.profileImagePreview}
                    alt="Profile Preview"
                    className="builder-profile-image"
                  />
                ) : (
                  <div className="placeholder">Upload Image</div>
                )}
                <input
                  className="builder-form-input"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </div>

            <div className="middle-form-section">
              <label className="builder-form-label">Full Name</label>
              <input
                className="builder-form-input"
                type="text"
                name="fullName"
                placeholder="Name"
                value={formData.fullName}
                onChange={handleChange}
              />

              <label className="builder-form-label">Email</label>
              <input
                className="builder-form-input"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                readOnly
              />

              <label className="builder-form-label">Location</label>
              <input
                className="builder-form-input"
                type="text"
                name="location"
                placeholder="Location"
                value={formData.location}
                onChange={handleChange}
              />
            </div>

            <div className="right-form-section">
              <label className="builder-form-label">Phone</label>
              <input
                className="builder-form-input"
                type="number"
                name="phone"
                placeholder="Phone number"
                value={formData.phone}
                onChange={handleChange}
              />

              <label className="builder-form-label">About</label>
              <input
                className="builder-form-input"
                type="text"
                name="companyname"
                placeholder="About yourself"
                value={formData.companyname}
                onChange={handleChange}
              />

              <button type="submit" className="update-button">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Buyerprofile;
