import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Pre-Construction/preconstructioncrd.css';
import Cookies from 'js-cookie';
import { customAxios } from '../../config/axios';

function PreConstructionCard() {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const token = Cookies.get('token');
        if (!token) {
          console.log('No token found');
          return;
        }

        const response = await customAxios.get(
          '/builderparent/get-allparentproperty',
          {
            headers: { 'x-access-token': token },
          }
        );

        if (response.data.status) {
          const preConstructionProperties = response.data.data
            .filter(
              (property) => property.typeofsale === 'Pre-Construction Sale'
            )
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 15); // Show latest 15

          setProperties(preConstructionProperties);
        } else {
          console.log('No properties found');
        }
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };

    fetchProperties();
  }, []);

  return (
    <>
      <div>
        <h1 className="pre-h1">Pre-Construction - Hot Deals</h1>
        <p style={{ fontFamily: 'Tahoma ,sans-serif', marginLeft: '1rem' }}>
          Best Projects of the Year
        </p>
        <div className="pre-view-btn-div">
          <button
            className="pre-view-btn"
            onClick={() => navigate('preconstruction')}
          >
            View More{' '}
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
              />
            </svg>
          </button>
        </div>
      </div>

      <div>
        <section className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="slick-slider">
                {properties.map((property) => (
                  <Link
                    key={property._id}
                    to={`/properties/${property._id}`}
                    title={property.projectname}
                  >
                    <div className="lean-card">
                      <img
                        src={
                          property.propertyImages ||
                          'https://images.pexels.com/photos/18038074/pexels-photo-18038074/free-photo-of-house-with-garden.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                        }
                        alt={property.projectname}
                      />
                      <div className="lean-card-content">
                        <h4 className="lp-h4">{property.projectname}</h4>
                        <p className="label--12 address">
                          <i className="lp-icon lp-icon--pin-map lp-icon--medium"></i>
                          {property.city}
                        </p>
                        <div className="item-footer">
                          <p>{property.projectdescription}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
                {properties.length === 0 && (
                  <p className="no-properties">
                    No Pre-Construction Sale properties available.
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PreConstructionCard;
