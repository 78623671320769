import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import Select from 'react-select';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { customAxios } from '../../config/axios';
import '../Parent Form/parentform.css';
import Autocomplete from 'react-google-autocomplete';

function ParentForm() {
  const [commonImages, setCommonImages] = useState([]);
  const [communityImages, setCommunityImages] = useState([]);
  const [parentId, setParentId] = useState('');
  const [formData, setFormData] = useState({
    typeofsale: 'Pre-Construction Sale',
    buildername: '',
    city: '',
    projectname: '',
    community: '',
    projectaddress: '',
    price: '', //new
    bed: '', //new
    squrefeet: '', //new
    parking: '', //new
    projectdescription: '',
    communitydescription: '',
    completionyear: '',
    builderaddress: '',
    builderdescription: '',
    housestyle: 'Multi Story', //new
    bath: '', //new
    garage: '', //new
    lotsize: '', //new
    depositstructure: '',
    builderincentives: '',
    amenities: [],
    projectlaunchingdate: '',
  });

  // Handle text inputs
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCustomSelectBox = (type, values) => {
    setFormData((prev) => ({ ...prev, [type]: values }));
  };

  const handleTheImageUploadCommonImages = (event) => {
    const filesArray = Array.from(event.target.files);
    if (filesArray) {
      setCommonImages((prevImages) => [...prevImages, ...filesArray]);
    }
  };

  const handleTheImageUploadCommunityImages = (event) => {
    const filesArray = Array.from(event.target.files);
    if (filesArray) {
      setCommunityImages((prevImages) => [...prevImages, ...filesArray]);
    }
  };

  const navigate = useNavigate();

  // Image previews
  const renderImagePreviews = (images, setImages) => {
    return images.map((image, index) => (
      <ImageItem
        key={index}
        index={index}
        image={image}
        images={images}
        setImages={setImages}
      />
    ));
  };

  const handleNavigation = (e) => {
    navigate(
      `/dashboard/builder/properties/parent-form/child-form/${parentId}`
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const Data = {
      buildingImages: commonImages,
      communityImages: communityImages,
      ...formData,
    };
    const formDataApi = new FormData();
    formDataApi.append('typeofsale', Data.typeofsale);
    formDataApi.append('buildername', Data.buildername);
    formDataApi.append('city', Data.city);
    formDataApi.append('projectname', Data.projectname);
    formDataApi.append('community', Data.community);
    formDataApi.append('projectaddress', Data.projectaddress);
    formDataApi.append('price', Data.price);
    formDataApi.append('bed', Data.bed);
    formDataApi.append('squrefeet', Data.squrefeet);
    formDataApi.append('parking', Data.parking);
    formDataApi.append('projectdescription', Data.projectdescription);
    formDataApi.append('communitydescription', Data.communitydescription);
    formDataApi.append('completionyear', Data.completionyear);
    formDataApi.append('builderaddress', Data.builderaddress);
    formDataApi.append('builderdescription', Data.builderdescription);
    formDataApi.append('housestyle', Data.housestyle);
    formDataApi.append('bath', Data.bath);
    formDataApi.append('garage', Data.garage);
    formDataApi.append('lotsize', Data.lotsize);
    formDataApi.append('depositstructure', Data.depositstructure);
    formDataApi.append('builderincentives', Data.builderincentives);
    formDataApi.append('projectlaunchingdate', Data.projectlaunchingdate);
    // formDataApi.append("buildingImages", JSON.stringify(Data.buildingImages));
    // formDataApi.append("communityImages", JSON.stringify(Data.communityImages));
    // formDataApi.append("amenities", JSON.stringify(Data.amenities));
    formData.amenities.forEach((amenity) => {
      formDataApi.append('amenities', amenity.value);
    });
    Data.communityImages.forEach((file) => {
      formDataApi.append('communityImages', file);
    });
    Data.buildingImages.forEach((file) => {
      formDataApi.append('buildingImages', file);
    });

    try {
      const { data } = await customAxios.post(
        '/builderparent/add-builderparent',
        formDataApi,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (data?.data) {
        navigate(`./child-form/${data.data._id}`);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const googleApi = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="parent-background">
        <div className="parent-form-container">
          <h1 className="parent-form-h1">Parent Property</h1>
          <form className="parent-form">
            <div className="form-row">
              <div className="form-group">
                <label className="parent-form-label">Sale Type</label>
                <select
                  id="house-style"
                  className="select-form-child"
                  name="typeofsale"
                  value={formData.typeofsale}
                  onChange={handleChange}
                >
                  <option value="Pre-Construction Sale">
                    Pre-Construction
                  </option>
                  <option value="Inventory Sale">Inventory</option>
                </select>
              </div>
              <div className="form-group">
                <label className="parent-form-label">Builder Name</label>
                <input
                  type="text"
                  className="parent-form-input-text"
                  name="buildername"
                  value={formData.buildername}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label className="parent-form-label">Project Name</label>
                <input
                  type="text"
                  className="parent-form-input-text"
                  name="projectname"
                  value={formData.projectname}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label className="parent-form-label">City</label>
                <Autocomplete
                  style={{ background: 'transparent' }}
                  apiKey={googleApi}
                  onPlaceSelected={(place) => {
                    const addressComponents = place.address_components;
                    const cityComponents = addressComponents.find((component) =>
                      component.types.includes('locality')
                    );
                    const city = cityComponents ? cityComponents.long_name : '';
                    setFormData((prev) => ({ ...prev, city }));
                  }}
                  placeholder="Enter City Name"
                  options={{
                    types: ['(cities)'],
                    componentRestrictions: { country: 'ca' },
                  }}
                  className="parent-form-input-text bg-white"
                />
              </div>

              <div className="form-group">
                <label className="parent-form-label">Community</label>
                <input
                  type="text"
                  className="parent-form-input-text"
                  value={formData.community}
                  onChange={handleChange}
                  name="community"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label className="parent-form-label">Project Address</label>
                <input
                  type="text"
                  className="parent-form-input-text"
                  name="projectaddress"
                  value={formData.projectaddress}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label className="parent-form-label">Price</label>
                <input
                  type="number"
                  className="parent-form-input-text"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="parent-form-label">Bed</label>
                <input
                  type="bed"
                  className="parent-form-input-text"
                  name="bed"
                  value={formData.bed}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="parent-form-label">Square feet</label>
                <input
                  type="text"
                  className="parent-form-input-text"
                  name="squrefeet"
                  value={formData.squrefeet}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="parent-form-label">Parking</label>
                <input
                  type="number"
                  className="parent-form-input-text"
                  name="parking"
                  value={formData.parking}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="parent-form-label">Bath</label>
                <input
                  type="number"
                  className="parent-form-input-text"
                  name="bath"
                  value={formData.bath}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="parent-form-label">Garage</label>
                <input
                  type="number"
                  className="parent-form-input-text"
                  name="garage"
                  value={formData.garage}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="parent-form-label">Lot size </label>
                <input
                  type="text"
                  className="parent-form-input-text"
                  name="lotsize"
                  value={formData.lotsize}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="parent-form-label">Builder Address </label>
                <input
                  type="text"
                  className="parent-form-input-text"
                  value={formData.builderaddress}
                  onChange={handleChange}
                  name="builderaddress"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label className="parent-form-label">Completion Year</label>
                <input
                  type="number"
                  className="parent-form-input-number"
                  name="completionyear"
                  value={formData.completionyear}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label className="parent-form-label">
                  Project Launching Date
                </label>
                <input
                  type="date"
                  className="parent-form-input-date"
                  value={formData.launchingDate}
                  onChange={handleChange}
                  name="launchingDate"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label className="parent-form-label">
                  Builder Description{' '}
                </label>
                <textarea
                  className="parent-form-textarea"
                  name="builderdescription"
                  value={formData.builderdescription}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="form-group">
                <label className="parent-form-label">
                  Project Description{' '}
                </label>
                <textarea
                  className="parent-form-textarea"
                  name="projectdescription"
                  value={formData.projectdescription}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label className="parent-form-label">
                  Community Description{' '}
                </label>
                <textarea
                  className="parent-form-textarea"
                  name="communitydescription"
                  onChange={handleChange}
                  value={formData.communitydescription}
                ></textarea>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label className="parent-form-label">Deposit Structure</label>
                <input
                  type="text"
                  className="parent-form-input-text"
                  name="depositstructure"
                  value={formData.depositstructure}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label className="parent-form-label">Builder Incentives</label>
                <input
                  type="text"
                  className="parent-form-input-text"
                  name="builderincentives"
                  value={formData.builderincentives}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label className="parent-form-label">Amenities</label>
                <div
                  className="amenities-options"
                  style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}
                >
                  <Select
                    isMulti
                    name="colors"
                    options={[
                      { value: 'Gym', label: 'Gym' },
                      { value: 'Swimming Pool', label: 'Swimming Pool' },
                      { value: 'Gym', label: 'Gym' },
                      { value: 'Parking', label: 'Parking' },
                      { value: 'Security', label: 'Security' },
                      { value: 'Garden', label: 'Garden' },
                      { value: 'Playground', label: 'Playground' },
                      { value: 'Hospital', label: 'Hospital' },
                      { value: 'Mall', label: 'Mall' },
                      { value: 'Clubhouse', label: 'Clubhouse' },
                      { value: 'Restaurants', label: 'Restaurants' },
                    ]}
                    value={formData.amenities}
                    onChange={(selected) =>
                      handleCustomSelectBox('amenities', selected)
                    }
                    className="basic-multi-select w-100"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="parent-form-label">Style</label>
                <select
                  id="housestyle"
                  className="select-form-child"
                  name="housestyle"
                  value={formData.housestyle}
                  onChange={handleChange}
                >
                  <option value="Multi Story">Multi Story</option>
                  <option value="Two Story">2 Story</option>
                  <option value="Three Story">3 Story</option>
                  <option value="Bungalow">3+ Story</option>
                  <option value="Split Level">Split level</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label className="parent-form-label">
                  Building Common Images{' '}
                </label>
                <input
                  type="file"
                  className="parent-form-input-file"
                  name="buildingImages"
                  accept="image/*"
                  multiple
                  style={{ backgroundColor: 'white' }}
                  onChange={handleTheImageUploadCommonImages}
                />
                <div className="image-preview">
                  {renderImagePreviews(commonImages, setCommonImages)}
                </div>
              </div>

              <div className="form-group">
                <label className="parent-form-label">Community Images </label>
                <input
                  type="file"
                  className="parent-form-input-file"
                  name="communityImages"
                  accept="image/*"
                  multiple
                  style={{ backgroundColor: 'white' }}
                  onChange={handleTheImageUploadCommunityImages}
                />
                <div className="image-preview">
                  {renderImagePreviews(communityImages, setCommunityImages)}
                </div>
              </div>
            </div>
            {/* Submit and Add Child Buttons */}
            <div className="form-row">
              <div className="form-group">
                <button className="submit-button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
              <div className="form-group">
                <button className="add-child-button" onClick={handleNavigation}>
                  Add Child Data
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </DndProvider>
  );
}

// ImageItem Component for draggable items
const ImageItem = ({ index, image, images, setImages }) => {
  const [, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    hover: (item) => {
      if (item.index !== index) {
        const newImages = [...images];
        newImages.splice(index, 0, newImages.splice(item.index, 1)[0]);
        setImages(newImages);
        item.index = index;
      }
    },
  });

  return (
    <div ref={(node) => drag(drop(node))} className="image-preview-item">
      <img
        src={URL.createObjectURL(image)}
        alt={`Preview ${index}`}
        className="image-preview-thumbnail"
      />
      <button
        onClick={() => removeImage(index, images, setImages)}
        className="remove-image-button"
      >
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
          />
        </svg>
      </button>
    </div>
  );
};

// Define the types for drag items
export const ItemTypes = {
  IMAGE: 'image',
};

const removeImage = (index, images, setImages) => {
  const newImages = [...images];
  newImages.splice(index, 1);
  setImages(newImages);
};

export default ParentForm;
