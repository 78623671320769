import React, { useState, useEffect } from 'react';
import './builders.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { FaBookmark } from 'react-icons/fa'; // Import bookmark icon
import { customAxios } from '../../config/axios';

const Builders = () => {
  const navigate = useNavigate();
  const [builders, setBuilders] = useState([]);
  const [savedBuilders, setSavedBuilders] = useState(new Set()); // Track saved builders
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 24;

  useEffect(() => {
    const fetchBuilders = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get('/api/get-allbuilder', {
          headers: { 'x-access-token': token },
        });

        if (response.data.status) {
          setBuilders(response.data.data);
        } else {
          console.error('Failed to fetch builders:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching builders:', error);
      }
    };

    const fetchSavedBuilders = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get('/agent/agent-getbuildersaved', {
          headers: { 'x-access-token': token },
        });

        if (response.data.status) {
          const savedIds = new Set(
            response.data.data.map((builder) => builder._id)
          );
          setSavedBuilders(savedIds);
        }
      } catch (error) {
        console.error('Error fetching saved builders:', error);
      }
    };

    fetchBuilders();
    fetchSavedBuilders();
  }, []);

  const handleSaveBuilder = async (builderId) => {
    if (savedBuilders.has(builderId)) return; // Prevent saving again

    try {
      const token = Cookies.get('token');
      const response = await customAxios.post(
        '/agent/agent-buildersaved',
        { id: builderId },
        { headers: { 'x-access-token': token } }
      );

      if (response.data.status) {
        setSavedBuilders((prev) => new Set(prev).add(builderId));
        toast.success('Builder saved successfully!');
      } else {
        toast.warning(response.data.message);
      }
    } catch (error) {
      console.error('Error saving builder:', error);
      toast.error('Error saving builder:');
    }
  };

  // Get current cards
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = builders.slice(indexOfFirstCard, indexOfLastCard);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <h1 style={{ marginLeft: '2rem', marginTop: '2rem', fontWeight: 'bold' }}>
        Builder List
      </h1>
      <div className="builder-flex-container ">
        {currentCards.map((person) => {
          const isSaved = savedBuilders.has(person._id);
          return (
            <div
              key={person._id}
              className={`builder-detail-card ${isSaved ? 'saved' : ''}`}
              onClick={() => navigate(`/builder-detail/${person._id}`)}
            >
              <span
                className={`save-icon ${isSaved ? 'saved-icon' : ''}`}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent navigating when clicking the icon
                  handleSaveBuilder(person._id);
                }}
              >
                <FaBookmark />
              </span>
              <img
                src={person.image}
                alt={person.name}
                className="profile-img"
              />
              <h3>{person.name}</h3>
              <p>{person.email}</p>
              <p>{person.phone}</p>
            </div>
          );
        })}
      </div>

      {/* Pagination */}
      <div className="builder-pagination">
        {Array.from(
          { length: Math.ceil(builders.length / cardsPerPage) },
          (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`page-btn ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          )
        )}
      </div>
    </>
  );
};

export default Builders;
