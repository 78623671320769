/** @format */

import React from 'react';

import AssignmentSales from './Images/edit-2.svg';
import InventroyHome from './Images/sms-tracking.svg';
import PreConstruction from './Images/messages.svg';
import { CardContainer, Conatainer } from './RedBuyersHelpSection.style';

export default function BuilderMatchHelpSection() {
  return (
    <Conatainer>
      <div className="Content">
        <h1>See How BuilderMatch Can Help</h1>
        <p>With Builder Deals</p>
      </div>
      <CardContainer>
        <div className="Card">
          <div className="icon">
            <img src={PreConstruction} alt="" />
          </div>
          <h1>Answer questions</h1>
          <p>
            We help people find their perfect home through pre-construction,
            inventory, and assignment sales with seamless marketing and realtor
            support. Our efficient process ensures the best customer experience
            with minimal delays.
          </p>
        </div>
        <div className="Card">
          <div className="icon">
            <img src={InventroyHome} alt="" />
          </div>
          <h1>Inventory Homes</h1>
          <p>
            We make finding your perfect home easy with expert guidance and a
            seamless process. Our network and marketing ensure you get the best
            properties hassle-free. Start your journey today and move into your
            dream home sooner!{' '}
          </p>
        </div>
        <div className="Card">
          <div className="icon">
            <img src={AssignmentSales} alt="" />
          </div>
          <h1>Get registered</h1>
          <p>
            Get registered on **BuilderMatch** to showcase your projects to a
            wider audience. Gain exposure through our marketing, realtor
            network, and targeted promotions. Connect with potential buyers and
            streamline your sales effortlessly.{' '}
          </p>
        </div>
      </CardContainer>
    </Conatainer>
  );
}
