/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  p {
    margin: 0;
  }
  .main {
    width: min(80rem, 95%);
    margin: 0 auto;
    .HomeCount {
      margin: 2rem 0;
      h1 {
        font-size: clamp(1rem, 1rem + 1vw, 1.375rem);
        font-weight: 700;
      }
    }
  }
  .MainCards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .loader {
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 20px auto;
    }
  }
`;

export const PropertyCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;

  /* Animation for loader */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media screen and (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 708px) {
    grid-template-columns: 1fr;
  }
`;

export const SerchContainer = styled.div`
  border-radius: 1.25rem;
  max-width: 40rem;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
  .icon {
    /* width: 2.7rem;
    padding: 1rem; */
  }
  input {
    flex: 1;
    border: none;
    outline: none;
    margin-left: 0.75rem;
  }
  button {
    border-radius: 0.9375rem;
    background: #0061df;
    box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
    padding: 1.2rem;
    color: #ffffff;
  }
`;

export const Vectors = styled.img`
  position: absolute;
  bottom: 6.5rem;
  width: 100%;
  z-index: -100;
`;
