import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './sellerproperty.css';
import Cookies from 'js-cookie';
import { customAxios } from '../../../../config/axios';

function SellerProperty() {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const token = Cookies.get('token');
        if (!token) {
          setError('No token found. Please log in again.');
          return;
        }

        const response = await customAxios.get('/seller/viewmyproperty', {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });

        if (response.data.status === true) {
          setProperties(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError('An error occurred while fetching properties.');
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  if (loading) {
    return <div>Loading properties...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Link to={'common-form'}>
        <button className="sellerproperties-button">Add Properties</button>
      </Link>

      <div
        className="sellerproperties-container-flex"
        style={{ padding: '1rem' }}
      >
        <div className="row">
          {properties.length === 0 ? (
            <div>No properties found</div>
          ) : (
            properties.map((property, index) => (
              <div key={index} className="col-sm-6 col-lg-3 mt-4">
                {/* Card component */}
                <div className="sellerproperties-altman-properties-item">
                  <Link to={`seller-property-detail/${property._id}`}>
                    <div className="sellerproperties-altman-properties-item-inner-wrapper">
                      <div className="sellerproperties-altman-properties-item-photo">
                        <canvas
                          width="529"
                          height="460"
                          className="sellerproperties-lazy-load-canvas-element"
                          style={{
                            backgroundImage: `url("https://images.pexels.com/photos/6074129/pexels-photo-6074129.jpeg?auto=compress&cs=tinysrgb&w=600")`,
                          }}
                        ></canvas>
                      </div>
                      <div className="sellerproperties-altman-properties-item-content-status-city search-ease">
                        <div>{property.status || 'N/A'}</div>
                        <div>{property.typeofsale || 'N/A'}</div>
                      </div>
                      <div className="sellerproperties-altman-properties-item-content search-ease">
                        <div className="sellerproperties-altman-properties-item-content-address">
                          {property.projectaddress || 'N/A'}
                          <div>{property.typeofsale || 'N/A'}</div>
                        </div>
                        <ul className="sellerproperties-altman-properties-item-content-features search-ease search-ps-0">
                          <li>{property.bath || 'N/A'} BD</li>
                          <li>{property.bed || 'N/A'} BA</li>
                          <li>{property.squarefeet || 'N/A'} sqft</li>
                        </ul>
                        <div className="sellerproperties-altman-properties-item-content-price search-ease">
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            {/* SVG icon for price */}
                          </svg>

                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            {/* SVG icon for delete or edit */}
                          </svg>
                        </div>
                      </div>
                      <div className="sellerproperties-altman-properties-item-link-label search-ease">
                        View Details
                      </div>
                    </div>
                  </Link>{' '}
                </div>
                {/* Card component end */}
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
}

export default SellerProperty;
