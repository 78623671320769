import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../LoginPage/login.css';
import login from '../LoginPage/Images/Login-amico.svg';
import { toast } from 'react-toastify';
import { customAxios } from '../../config/axios';

function Login() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { data } = await customAxios.post('/api/login', formData);

      if (data.auth) {
        Cookies.set('token', data.token, { expires: 1 });
        Cookies.set('username', data.username);
        Cookies.set('userRole', data.role, { expires: 1 });

        switch (data.role) {
          case 'Builder':
            navigate('/dashboard/builder');
            toast.success('LoggedIn');
            break;
          case 'Seller':
            navigate('/dashboard/seller');
            toast.success('LoggedIn');
            break;
          case 'Agent':
            navigate('/dashboard/agent');
            toast.success('LoggedIn');
            break;
          case 'Admin':
            navigate('/dashboard/admin');
            toast.success('LoggedIn');
            break;
          default:
            navigate('/');
        }
      } else {
        toast.error(data.message || 'Login failed');
      }
    } catch (err) {
      toast.warning(
        err.response?.data?.message || 'Something went wrong, try again later.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2>Login</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            onChange={handleChange}
            value={formData.email}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            required
            onChange={handleChange}
            value={formData.password}
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        <p className="register-link">
          Don't have an account? <Link to="/signup">Register here</Link>
        </p>
      </div>

      <div className="auth-image">
        <img src={login} alt="Login Illustration" />
      </div>
    </div>
  );
}

export default Login;
