import React from 'react';
import SellerDashboard from '../components/SellerDashboard/SellerDashboard';
import { Route, Routes } from 'react-router-dom';
import Sellerdetails from '../components/SellerDashboard/Components/SellerDetails/Sellerdetails';
import SellerProperty from '../components/SellerDashboard/Components/SellerProperties/SellerProperty';
import CommonForm from '../components/Inventory,AssignmentForm/CommonForm';
import SellerLead from '../components/SellerDashboard/Components/SellerLeads/SellerLead';
import SellerDealers from '../components/SellerDashboard/Components/SellerDealers/SellerDealers';
import DashboardMessage from '../page/DashboardMessage';
import SellerPropertyDetail from '../components/SellerDashboard/Components/SellerPropertyDetail/SellerPropertyDetail';
import SellerPropertyUpdate from '../components/SellerDashboard/Components/SellerPropertyUpdate/SellerPropertyUpdate';

function SellerDashboardRoute() {
  return (
    <Routes>
      <Route path="/" element={<SellerDashboard />}>
        <Route index element={<DashboardMessage />} />
        <Route path="seller-details" element={<Sellerdetails />} />
        <Route path="seller-properties" element={<SellerProperty />} />
        <Route path="seller-properties/common-form" element={<CommonForm />} />
        <Route path="seller-lead" element={<SellerLead />} />
        <Route path="seller-dealers" element={<SellerDealers />} />
        <Route
          path="seller-properties/seller-property-detail/:id"
          element={<SellerPropertyDetail />}
        />
        <Route
          path="seller-properties/seller-property-detail/:id/seller-property-update/:id"
          element={<SellerPropertyUpdate />}
        />
      </Route>
    </Routes>
  );
}

export default SellerDashboardRoute;
