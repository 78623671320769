/** @format */

import React from 'react';
import {
  Container,
  FooterLinkContainer,
  FooterLink,
  FooterNlink,
  Button,
  ContactUs,
} from './Footer.style';
import Facebook from './Images/facebook.svg';
import Twitter from './Images/twitter.svg';
import LinkedLin from './Images/linkedin.png';
import Instagram from './Images/instagram.png';
import Phone from './Images/Phone.png';
import Email from './Images/Email.svg';
import footimg from './Images/footer.svg';
export default function Footer() {
  return (
    <Container>
      <p style={{ textAlign: 'center' }}>
        {' '}
        © 2021 BuilderMatch.com. All rights reserved.
      </p>
      <p
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={Phone} alt="PhoneIcon" style={{ marginRight: '0.4rem' }} />
        905-567-0200{' '}
        <img
          src={Email}
          alt="PhoneIcon"
          style={{ marginRight: '0.4rem', marginLeft: '3rem' }}
        />
        info@BuilderMatch.com{' '}
      </p>
      <p style={{ textAlign: 'center' }}>
        {' '}
        BuilderMatch Realty. Brokerage, is fully registered under the Real
        Estate and Business brokerage, Act, 2002 of Ontario.
      </p>
      <FooterLinkContainer>
        {/* <div className="Buy-sell">
                    <p>buy & sell</p>
                    <FooterLink>Sell</FooterLink>
                    <FooterLink>Find Home Value</FooterLink>
                    <FooterLink>Browse Home</FooterLink>
                    <FooterLink>Sold Homes</FooterLink>
                    <FooterLink>Cash Offer</FooterLink>
                    <FooterLink>Buy</FooterLink>
                    <FooterLink>New Construction</FooterLink>
                    <FooterLink>Pricing</FooterLink>
                    <FooterLink>Down Payment</FooterLink>
                </div>
                <div className="Investment">
                    <p>INVESTMENT</p>
                    <FooterLink>Co-Investment</FooterLink>
                </div>
                <div className="Company">
                    <p>Company</p>
                    <FooterLink>About</FooterLink>
                    <FooterLink>Contact Us</FooterLink>
                    <FooterLink>Vendors</FooterLink>
                    <FooterLink>Careers</FooterLink>
                    <FooterLink>Privacy Policy</FooterLink>
                    <FooterLink>Terms & Conditions</FooterLink>
                </div>
                <div className="Learn">
                    <p>Learn</p>
                    <FooterLink>Seller FAQs</FooterLink>
                    <FooterLink>Buyer FAQs</FooterLink>
                    <FooterLink>Blogs</FooterLink>
                </div>
                <div className="Location">
                    <p>Locations</p>
                    <FooterLink>Greater Toronto Area</FooterLink>
                    <FooterLink>Region of Peel</FooterLink>
                    <FooterLink>Region of Hamilton</FooterLink>
                    <FooterLink>Region of Waterloo</FooterLink>
                    <FooterNlink>View All Locations</FooterNlink>
                    <Button>
                        <div className="warapper">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none">
                                <path
                                    d="M6.00006 0.5C2.69181 0.5 6.10835e-05 3.19175 6.10835e-05 6.49625C-0.0216889 11.33 5.77206 15.338 6.00006 15.5C6.00006 15.5 12.0218 11.33 12.0001 6.5C12.0001 3.19175 9.30831 0.5 6.00006 0.5ZM6.00006 9.5C4.34256 9.5 3.00006 8.1575 3.00006 6.5C3.00006 4.8425 4.34256 3.5 6.00006 3.5C7.65756 3.5 9.00006 4.8425 9.00006 6.5C9.00006 8.1575 7.65756 9.5 6.00006 9.5Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <p>Map Search</p>
                    </Button>
                </div> */}

        <div style={{ padding: '1rem' }}>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it t Lorem Ipsum is simply dummy text of the printing
            and typesetting industry. Lorem Ipsum has been the industry's
            standard dummy text ever since the 1500s, when an unknown printer
            took a galley of type and scrambled it t
          </p>
        </div>
        <div style={{ padding: '1rem' }}>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it t Lorem Ipsum is simply dummy text of the printing
            and typesetting industry. Lorem Ipsum has been the industry's
            standard dummy text ever since the 1500s, when an unknown printer
            took a galley of type and scrambled it t
          </p>
        </div>
      </FooterLinkContainer>
      <img src={footimg} alt="" style={{ width: '100%' }} />
      <ContactUs>
        {/* <p>Conatact us</p>
                <div className="socialMedia">
                    <img src={Facebook} alt="Facebook" />
                    <img src={Twitter} alt="Twitter" />
                    <img src={LinkedLin} alt="LinkedLin" />
                    <img src={Instagram} alt="Instagram" />
                </div>
                <div className="Contact_info">
                    <div className="contact">
                        <img src={Phone} alt="PhoneIcon" />
                        <p>905-567-0200</p>
                    </div>
                    <div className="contact">
                        <img src={Email} alt="EmailIcon" />
                        <p>info@BuilderMatch.com</p>
                    </div>
                </div>
                <p className="CopyRight">
                    © 2021 BuilderMatch.com. All rights reserved.
                </p>
                <p className="Act">
                    BuilderMatch Realty. Brokerage, is fully registered under the Real Estate and Business brokerage, Act, 2002 of Ontario.
                </p> */}

        <div className="socialMedia">
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="white"
            viewBox="0 0 24 24"
            style={{ marginLeft: '1rem' }}
          >
            <path
              fill-rule="evenodd"
              d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="white"
            viewBox="0 0 24 24"
            style={{ marginLeft: '1rem' }}
          >
            <path d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z" />
          </svg>
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            style={{ marginLeft: '1rem' }}
          >
            <path
              fill="white"
              fill-rule="evenodd"
              d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div className="name">
          <h4>Builder Match</h4>
        </div>
        <div className="end">
          <p>All rights reserved.</p>
        </div>
      </ContactUs>
    </Container>
  );
}
