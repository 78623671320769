/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 3rem;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 500;
  }
  p {
    color: #414141;
    font-family: Inter;
    font-size: 1rem;
    font-weight: 400;
  }
`;
export const SchoolContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
`;
export const School = styled.div`
  padding: 1.62rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 97, 223, 0.15);
`;
