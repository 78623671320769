import React, { useState, useEffect } from 'react';
import '../AdminDashboard/admindashboard.css';
import Cookies from 'js-cookie';
import { customAxios } from '../../config/axios';

function AdminDashboard() {
  const [activeTab, setActiveTab] = useState('Seller');
  const [data, setData] = useState({ Seller: [], Builder: [], Agents: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');
        const headers = { headers: { 'x-access-token': token } };

        const responses = await Promise.all([
          customAxios.get('/admin/view-seller', headers),
          customAxios.get('/admin/view-builder', headers),
          customAxios.get('/admin/view-agent', headers),
        ]);

        setData({
          Seller: responses[0].data.data,
          Builder: responses[1].data.data,
          Agents: responses[2].data.data,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const toggleStatus = async (id, field) => {
    try {
      const token = Cookies.get('token');
      const isSellerUpdate = field === 'ownerConfirmation';
      const url = isSellerUpdate
        ? '/admin/update-sellerstatus'
        : '/admin/update-builder';

      await customAxios.put(
        url,
        {
          id,
          [field]: !data[activeTab].find((item) => item._id === id)[field],
        },
        {
          headers: { 'x-access-token': token },
        }
      );

      setData((prevData) => {
        const updatedData = { ...prevData };
        updatedData[activeTab] = updatedData[activeTab].map((item) =>
          item._id === id ? { ...item, [field]: !item[field] } : item
        );
        return updatedData;
      });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="admin-dashboard">
      <div className="admintabs">
        {['Seller', 'Builder', 'Agents'].map((tab) => (
          <button
            key={tab}
            className={activeTab === tab ? 'active' : ''}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <h1 className="adminh1">{activeTab}</h1>

      <table className="admintable">
        <thead>
          <tr className="admintr">
            <th className="adminth">Name</th>
            <th className="adminth">Email</th>
            <th className="adminth">Phone</th>
            <th className="adminth">Company Name</th>
            <th className="adminth">Brokerage</th>
            <th className="adminth">Location</th>
            {activeTab === 'Seller' && <th className="adminth">Owner Confirmation</th>}
            {activeTab === 'Builder' && <th className="adminth">Authorized</th>}
          </tr>
        </thead>
        <tbody>
          {data[activeTab].map((item, index) => (
            <tr className="admintr" key={index}>
              <td className="admintd">{item.name}</td>
              <td className="admintd">{item.email}</td>
              <td className="admintd">{item.phone}</td>
              <td className="admintd">{item.builderName}</td>
              <td className="admintd">{item.brokerage}</td>
              <td className="admintd">{item.location}</td>
              {activeTab === 'Seller' && (
                <td>
                  <button
                    className={
                      item.ownerConfirmation ? 'status-btn green' : 'status-btn red'
                    }
                    onClick={() => toggleStatus(item._id, 'ownerConfirmation')}
                  >
                    {item.ownerConfirmation ? 'True' : 'False'}
                  </button>
                </td>
              )}
              {activeTab === 'Builder' && (
                <td>
                  <button
                    className={
                      item.authorized ? 'status-btn green' : 'status-btn red'
                    }
                    onClick={() => toggleStatus(item._id, 'authorized')}
                  >
                    {item.authorized ? 'True' : 'False'}
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminDashboard;
