import React, { useState } from 'react';
import '../MapSearch/mapsearch.css';
import { useNavigate } from 'react-router-dom';

function MapSearch() {
  const navigate = useNavigate();
  const [selectedHomeTypes, setSelectedHomeTypes] = useState([]);
  const [selectedAgentListings, setSelectedAgentListings] = useState(
    'Agent Listings(500+)'
  );
  const [selectedPrice, setSelectedPrice] = useState('Any Price');
  const [selectedBeds, setSelectedBeds] = useState('All Beds');
  const [selectedMoreFilters, setSelectedMoreFilters] = useState([]);

  // Handle checkbox changes for Home Types
  const handleHomeTypeChange = (e) => {
    const value = e.target.value;
    setSelectedHomeTypes((prevState) =>
      prevState.includes(value)
        ? prevState.filter((item) => item !== value)
        : [...prevState, value]
    );
  };

  // Handle checkbox changes for More Filters
  const handleMoreFilterChange = (e) => {
    const value = e.target.value;
    setSelectedMoreFilters((prevState) =>
      prevState.includes(value)
        ? prevState.filter((item) => item !== value)
        : [...prevState, value]
    );
  };

  // Handle click to show or hide the dropdown options
  const toggleDropdown = (dropdown) => {
    const content = document.querySelector(`.${dropdown}-options`);
    content.style.display =
      content.style.display === 'block' ? 'none' : 'block';
  };

  const handleNavigate = () => {
    navigate('/map-search'); // Navigate to the map-search route
  };

  return (
    <>
      {' '}
      <div className="filter-bar">
        <input
          type="text"
          className="search-input"
          placeholder="Toronto, Canada"
        />
        <button className="search-button">
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-width="2"
              d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
            />
          </svg>
        </button>

        <button onClick={handleNavigate} className="search-button">
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"
            />
          </svg>
        </button>

        {/* Custom Dropdown for Agent Listings */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('agent-listings')}
          >
            {selectedAgentListings}
          </button>
          <div className="dropdown-options agent-listings-options">
            <label>
              <input
                type="radio"
                name="agent-listings"
                value="Agent Listings(500+) "
                checked={selectedAgentListings === 'Agent Listings(500+)'}
                onChange={(e) => setSelectedAgentListings(e.target.value)}
              />
              Agent Listings(500+)
            </label>
            <label>
              <input
                type="radio"
                name="agent-listings"
                value="Agent Listings(1000+)"
                checked={selectedAgentListings === 'Agent Listings(1000+)'}
                onChange={(e) => setSelectedAgentListings(e.target.value)}
              />
              Agent Listings(1000+)
            </label>
          </div>
        </div>

        {/* Custom Dropdown for Price */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('price')}
          >
            {selectedPrice}
          </button>
          <div className="dropdown-options price-options">
            <label>
              <input
                type="radio"
                name="price"
                value="Any Price"
                checked={selectedPrice === 'Any Price'}
                onChange={(e) => setSelectedPrice(e.target.value)}
              />
              Any Price
            </label>
            <label>
              <input
                type="radio"
                name="price"
                value="Below $500,000"
                checked={selectedPrice === 'Below $500,000'}
                onChange={(e) => setSelectedPrice(e.target.value)}
              />
              Below $500,000
            </label>
            <label>
              <input
                type="radio"
                name="price"
                value="Above $500,000"
                checked={selectedPrice === 'Above $500,000'}
                onChange={(e) => setSelectedPrice(e.target.value)}
              />
              Above $500,000
            </label>
          </div>
        </div>

        {/* Custom Dropdown for Beds */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('beds')}
          >
            {selectedBeds}
          </button>
          <div className="dropdown-options beds-options">
            <label>
              <input
                type="radio"
                name="beds"
                value="All Beds"
                checked={selectedBeds === 'All Beds'}
                onChange={(e) => setSelectedBeds(e.target.value)}
              />
              All Beds
            </label>
            <label>
              <input
                type="radio"
                name="beds"
                value="1 Bed"
                checked={selectedBeds === '1 Bed'}
                onChange={(e) => setSelectedBeds(e.target.value)}
              />
              1 Bed
            </label>
            <label>
              <input
                type="radio"
                name="beds"
                value="2+ Beds"
                checked={selectedBeds === '2+ Beds'}
                onChange={(e) => setSelectedBeds(e.target.value)}
              />
              2+ Beds
            </label>
          </div>
        </div>

        {/* Custom Dropdown for Home Types (Checkboxes) */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('home-types')}
          >
            {selectedHomeTypes.length
              ? selectedHomeTypes.join(', ')
              : 'Select Home Types'}
          </button>
          <div className="dropdown-options home-types-options">
            <label>
              <input
                type="checkbox"
                value="All Home Types"
                checked={selectedHomeTypes.includes('All Home Types')}
                onChange={handleHomeTypeChange}
              />
              All Home Types
            </label>
            <label>
              <input
                type="checkbox"
                value="Apartment"
                checked={selectedHomeTypes.includes('Apartment')}
                onChange={handleHomeTypeChange}
              />
              Apartment
            </label>
            <label>
              <input
                type="checkbox"
                value="House"
                checked={selectedHomeTypes.includes('House')}
                onChange={handleHomeTypeChange}
              />
              House
            </label>
            <label>
              <input
                type="checkbox"
                value="Condo"
                checked={selectedHomeTypes.includes('Condo')}
                onChange={handleHomeTypeChange}
              />
              Condo
            </label>
          </div>
        </div>

        {/* Custom Dropdown for More Filters */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('more-filters')}
          >
            More Filters
          </button>
          <div className="dropdown-options more-filters-options">
            <label>
              <input
                type="checkbox"
                value="For Sale By Agent"
                checked={selectedMoreFilters.includes('For Sale By Agent')}
                onChange={handleMoreFilterChange}
              />
              For Sale By Agent
            </label>
            <label>
              <input
                type="checkbox"
                value="For Sale By Owner"
                checked={selectedMoreFilters.includes('For Sale By Owner')}
                onChange={handleMoreFilterChange}
              />
              For Sale By Owner
            </label>
            <label>
              <input
                type="checkbox"
                value="New Construction"
                checked={selectedMoreFilters.includes('New Construction')}
                onChange={handleMoreFilterChange}
              />
              New Construction
            </label>
            <label>
              <input
                type="checkbox"
                value="New Listings (Past Week)"
                checked={selectedMoreFilters.includes(
                  'New Listings (Past Week)'
                )}
                onChange={handleMoreFilterChange}
              />
              New Listings (Past Week)
            </label>
          </div>
        </div>
      </div>
      <div className="mapsearch-container">
        <div className="cards-container-map">
          <div className="map-container-flex" style={{ padding: '1rem' }}>
            <div className="row">
              {[...Array(8)].map((_, index) => (
                <div key={index} className="col-sm-12 col-md-6 mt-4">
                  {/* Card component */}
                  <div className="map-altman-properties-item">
                    <a href="#" title={`Property ${index + 1}`} tabIndex="-1">
                      <div className="map-altman-properties-item-inner-wrapper">
                        <div className="map-altman-properties-item-photo">
                          <canvas
                            width="529"
                            height="460"
                            className="map-lazy-load-canvas-element"
                            data-lazyload-src="https://imageproxy.agentimage.com/530x460/https://www.thealtmanbrothers.com/wp-content/uploads/2023/08/01.jpg"
                            style={{
                              backgroundImage:
                                'url("https://imageproxy.agentimage.com/530x460/https://www.thealtmanbrothers.com/wp-content/uploads/2023/08/01.jpg")',
                            }}
                          ></canvas>
                        </div>
                        <div className="map-altman-properties-item-content-status-city search-ease">
                          <div>Past Listing</div>
                          <div>Los Angeles</div>
                        </div>
                        <div className="map-altman-properties-item-content search-ease">
                          <div className="map-altman-properties-item-content-address">
                            13187 Chalon Rd
                            <div>Los Angeles</div>
                          </div>
                          <ul className="map-altman-properties-item-content-features search-ease search-ps-0">
                            <li>7 BD</li>
                            <li>9 BA</li>
                            <li>14,000 sqft</li>
                          </ul>
                          <div className="map-altman-properties-item-content-price search-ease">
                            $27,688,000
                          </div>
                        </div>
                        <div className="map-altman-properties-item-link-label search-ease">
                          View Details
                        </div>
                      </div>
                    </a>
                  </div>
                  {/* Card component end */}
                </div>
              ))}
            </div>
          </div>

          <div className="map-container" style={{ width: '100%' }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=..."
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default MapSearch;
