import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './builderdetails.css';
import Cookies from 'js-cookie';
import { customAxios } from '../../config/axios';

function BuilderDetails() {
  const { id } = useParams(); // Get the builder ID from the URL
  const [builder, setBuilder] = useState(null);
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchBuilderData = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get(`/api/get-builder/${id}`, {
          headers: { 'x-access-token': token },
        });

        if (response.data.status) {
          setBuilder(response.data.data); // Set builder data
          setProperties(response.data.data.properties || []); // If builder has properties
        } else {
          console.error('Failed to fetch builder:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching builder data:', error);
      }
    };

    fetchBuilderData();
  }, [id]);

  const totalPages = Math.ceil(properties.length / itemsPerPage);

  const paginatedProperties = properties.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      {builder ? (
        <>
          <div>
            <h1 style={{ fontWeight: 'bold', marginLeft: '5rem' }}>
              {builder.name}'s Profile
            </h1>
          </div>
          <div className="builder-profile">
            <div className="profile-photo">
              <img
                src={
                  builder.image ||
                  'https://i.pinimg.com/736x/47/3e/84/473e84e35274f087695236414ff8df3b.jpg'
                }
                alt="Builder Profile"
              />
            </div>
            <div className="profile-description">
              <h2>{builder.name}</h2>
              <p>{builder.builderdescription}</p>{' '}
              {/* Assuming builder has a description */}
            </div>
          </div>

          <div className="cards-container">
            <div className="card">
              {builder.completedsales || 'N/A'} + Sales
            </div>
            <div className="card">
              {builder.completedproject || 'N/A'} + Projects
            </div>
            <div className="card">
              {builder.totalbuyers || 'N/A'} + Customers
            </div>
          </div>

          <div className="properties-container">
            <h1
              style={{
                textAlign: 'center',
                marginTop: '2rem',
                marginBottom: '2rem',
                fontWeight: 'bold',
              }}
            >
              Properties
            </h1>
            <div className="grid-container">
              {paginatedProperties.map((property) => (
                <div
                  key={property.id}
                  className="builder-details-card-altman-properties-item"
                >
                  <a href="##" title={property.title} tabIndex="-1">
                    <div className="builder-details-card-altman-properties-item-inner-wrapper">
                      <div className="builder-details-card-altman-properties-item-photo">
                        <img src={property.imageUrl} alt={property.title} />
                      </div>
                      <div className="builder-details-card-altman-properties-item-content-status-city search-ease">
                        <div>Past Listing</div>
                        <div>{property.city}</div>
                      </div>
                      <div className="builder-details-card-altman-properties-item-content search-ease">
                        <div className="builder-details-card-altman-properties-item-content-address">
                          {property.address}
                          <div>{property.city}</div>
                        </div>
                        <ul className="builder-details-card-altman-properties-item-content-features search-ease search-ps-0">
                          <li>{property.bedrooms} BD</li>
                          <li>{property.bathrooms} BA</li>
                          <li>{property.size}</li>
                        </ul>
                        <div className="builder-details-card-altman-properties-item-content-price search-ease">
                          {property.price}
                        </div>
                      </div>
                      <div className="builder-details-card-altman-properties-item-link-label search-ease">
                        View Details
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>

            <div className="pagination">
              <button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>

          <div className="builder-detail-contact">
            <div className="contact-container">
              <div className="contact-image">Logo</div>
              <div className="contact-info">
                <p>
                  <strong>Address:</strong> {builder.address}
                </p>
                <p>
                  <strong>Email:</strong> {builder.email}
                </p>
                <p>
                  <strong>Phone:</strong> {builder.phone}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Loading builder details...</p>
      )}
    </>
  );
}

export default BuilderDetails;
