import React from 'react';
import { Container } from './ErrroScreen.style';
export default function ErrorScreen({ message }) {
  return (
    <Container>
      <div className="error_screen">
        <p>{message}</p>
      </div>
    </Container>
  );
}
