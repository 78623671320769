import React, { useEffect, useRef } from 'react';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import { Container } from './ImageViewer.style';
export const ImageViewer = ({ Images }) => {
  const viewerRef = useRef(null);

  useEffect(() => {
    if (viewerRef.current) {
      const viewer = new Viewer(viewerRef.current, {
        // Options for Viewer.js (if needed)
        // For example: options for zooming, navigation, etc.
      });

      return () => {
        viewer.destroy();
      };
    }
  }, [Images]);

  return (
    <Container ref={viewerRef} id="Floor_plan">
      <p>Layouts</p>
      <div className="image_container">
        {Images?.map((data, index) => (
          <img
            src={`https://dev.redbuyers.com${data.file_url}`}
            alt={`${index + 1}`}
            key={index}
          />
        ))}
      </div>
    </Container>
  );
};
