export const ConvertStringToHtml = (StringData) => {
  let htmlData = '';
  const NewSplitData = StringData?.split('\n');
  if (NewSplitData) {
    for (let string of NewSplitData) {
      htmlData += `<p>${string.replace(/ /g, '&nbsp;')}</p>`;
    }
  }

  return htmlData;
};
