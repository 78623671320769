import React from 'react';
import PropertiesDetailMain from '../components/PropertiesDetailMain/PropertiesDetailMain';
import RecommentedHome from '../components/RecommentedHomes/RecommentedHome';

function NewProperties() {
  return (
    <>
      <PropertiesDetailMain />
      <RecommentedHome />
    </>
  );
}

export default NewProperties;
