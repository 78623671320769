/** @format */

import styled from 'styled-components';

export const VideoContainer = styled.div`
  padding: 4rem 0;
  border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-size: clamp(1rem, 1rem + 1vw, 1.25rem);
    font-weight: 500;
  }
  :where([data-media-player][data-view-type='video']) {
    background-color: white;
  }
  .media-player {
    --media-font-family: sans-serif;
    --media-controls-color: #f5f5f5;
    --media-focus-ring: 0 0 0 3px rgb(78 156 246);
  }
  .vds-play-button {
    display: none;
  }
  .vds-controls-group {
    opacity: ${(props) => (props.playerCheck ? 1 : 0)};
  }
  .custom_button {
    display: block;
    position: absolute;
    left: 45%;
    transform: translateX(50%);
    opacity: ${(props) => (props.playerCheck ? 0 : 1)};
    transition: all 1s ease-in-out;
    z-index: 15;
    background-color: white;
    border-radius: 50%;
    svg path {
      fill: #0061df;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(1.4);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #fefff1;
      opacity: 0.7;
      z-index: -1;
      transition: all 1s ease-in-out;
    }
  }
  .vds-button[data-paused] .pause-icon,
  .vds-button:not([data-paused]) .play-icon {
    display: none;
  }
  &:hover .custom_button {
    opacity: 1;
    transition: all 1.5s ease-in-out;
  }
  &:hover .custom_button::after {
    transform: scale(1);
    transition: all 1.5s ease-in-out;
  }
`;
