import axios from 'axios';
import Cookies from 'js-cookie';
const API = process.env.REACT_APP_API_URL;

const customAxios = axios.create({
  baseURL: API,
  timeout: 10000, // 10 seconds timeout
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to dynamically update x-access-token
customAxios.interceptors.request.use(
  (config) => {
    // Fetch the latest x-access-token from cookies
    const xAccessToken = Cookies.get('token'); // Custom token

    if (xAccessToken) {
      config.headers['x-access-token'] = xAccessToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for handling errors
customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        console.error('Unauthorized! Redirecting to login...');
        // Handle unauthorized cases (e.g., redirect to login)
      }
    }
    return Promise.reject(error);
  }
);

// API Methods
const api = {
  get: (url, config) => customAxios.get(url, config),
  post: (url, data, config) => customAxios.post(url, data, config),
  put: (url, data, config) => customAxios.put(url, data, config),
  delete: (url, config) => customAxios.delete(url, config),
};

export { customAxios, api };
