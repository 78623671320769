/** @format */

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fffff;
  .main {
    min-height: 4rem;
    width: min(80rem, 90%);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  .Section1 {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .Section2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
  @media screen and (min-width: 760px) {
    .Section1 {
      justify-content: space-between;
      .HamBurggerMenu {
        display: none; /* Hide hamburger menu on larger screens */
      }
    }
  }
  @media screen and (max-width: 760px) {
    .Section2 {
      display: none; /* Hide regular navigation links on smaller screens */
    }
    .Section3 {
      display: none; /* Hide login/signup links on smaller screens */
    }
  }
`;

export const Logo = styled(Link)`
  img {
    margin-top: -10px;
  }
  text-decoration: none;
  color: black;
`;

export const NavLink = styled(Link)`
  color: black;
  font-family: Tahoma (sans-serif);
  font-size: clamp(1rem, 1rem + 1vw, 1.125rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  position: relative;
  &:hover::after {
    content: '';
    position: absolute;
    bottom: -15%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.0625rem;
    background: #0061df;
    width: 0.75rem;
    height: 0.125rem;
  }
  &::before {
    content: '';
    display: ${(props) => (props.Check ? 'block' : 'none')};
    position: absolute;
    bottom: -15%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.0625rem;
    background: #fff;
    width: 0.75rem;
    height: 0.125rem;
  }
`;

export const Login = styled(Link)`
  color: black;
  font-family: Tahoma (sans-serif);
  font-size: clamp(1rem, 1rem + 1vw, 1.3rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  margin-right: 1.5rem;

  &:hover {
    color: #0061df;
  }

  &::before {
    content: '';
  }
`;

export const Register = styled(Link)`
  color: black;
  font-family: Tahoma (sans-serif);
  font-size: clamp(1rem, 1rem + 1vw, 1.3rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  margin-right: 0.75rem;

  &:hover {
    color: #0061df;
  }
`;
export const MobileLinks = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};
  z-index: 1000;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 4rem; /* Adjust this value based on your navbar height */
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  width: 100%; /* Full width for mobile view */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  a {
    color: black;
    font-family: Tahoma, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: #0061df;
      font-weight: bold;
    }
  }

  @media screen and (min-width: 760px) {
    display: none; /* Hide mobile links on larger screens */
  }
`;
