import React from 'react';
import BuyerDashboard from '../components/BuyerDaashboard/BuyerDashboard';
import { Route, Routes } from 'react-router-dom';
import Buyerprofile from '../components/BuyerDaashboard/Components/BuyerProfile/Buyerprofile';
import SavedProperties from '../components/BuyerDaashboard/Components/SavedProperties/SavedProperties';
import Sellerinfo from '../components/BuyerDaashboard/Components/SellerInfo/Sellerinfo';
import DashboardMessage from '../page/DashboardMessage';

function BuyerDashboardRoute() {
  return (
    <Routes>
      <Route path="/" element={<BuyerDashboard />}>
        <Route index element={<DashboardMessage />} />
        <Route path="buyerprofile" element={<Buyerprofile />} />
        <Route path="saved-properties" element={<SavedProperties />} />
        <Route path="seller-info" element={<Sellerinfo />} />
      </Route>
    </Routes>
  );
}

export default BuyerDashboardRoute;
