import React from 'react';
import { CustomBarContainer, Progress } from './CustomBar.style';
export default function CustomBar({ totalSlides, currentSlide }) {
  const width = ((currentSlide + 1) / totalSlides) * 100 + '%';
  return (
    <CustomBarContainer>
      <Progress PWidth={width} />
    </CustomBarContainer>
  );
}
