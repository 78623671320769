import React from 'react';

import Autocomplete from 'react-google-autocomplete';

import { Container, SerchContainer } from './HeroHome.style';

export default function HeroHome() {
  const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  return (
    <Container>
      <div className="main">
        <div className="leftSection">
          <h1 className="hero-title">
            Explore Exclusive <br className="breaker" />
            Builder Deals and Beyond...
          </h1>
          <SerchContainer>
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
              >
                <path
                  d="M22.0007 22.0001C23.009 22.0001 23.8725 21.6407 24.5912 20.9221C25.3086 20.2046 25.6673 19.3417 25.6673 18.3334C25.6673 17.3251 25.3086 16.4616 24.5912 15.7429C23.8725 15.0255 23.009 14.6667 22.0007 14.6667C20.9923 14.6667 20.1294 15.0255 19.412 15.7429C18.6933 16.4616 18.334 17.3251 18.334 18.3334C18.334 19.3417 18.6933 20.2046 19.412 20.9221C20.1294 21.6407 20.9923 22.0001 22.0007 22.0001ZM22.0007 39.6459C21.7562 39.6459 21.5118 39.6001 21.2673 39.5084C21.0229 39.4167 20.809 39.2945 20.6257 39.1418C16.1645 35.2001 12.834 31.5414 10.634 28.1656C8.43398 24.7886 7.33398 21.6334 7.33398 18.7001C7.33398 14.1167 8.8086 10.4654 11.7578 7.74591C14.7058 5.02647 18.1201 3.66675 22.0007 3.66675C25.8812 3.66675 29.2955 5.02647 32.2435 7.74591C35.1927 10.4654 36.6673 14.1167 36.6673 18.7001C36.6673 21.6334 35.5673 24.7886 33.3673 28.1656C31.1673 31.5414 27.8368 35.2001 23.3757 39.1418C23.1923 39.2945 22.9784 39.4167 22.734 39.5084C22.4895 39.6001 22.2451 39.6459 22.0007 39.6459Z"
                  fill="#13131A"
                />
              </svg>
            </div>
            <Autocomplete
              style={{ background: 'transparent' }}
              apiKey={googleApiKey}
              onPlaceSelected={(place) => {
                const addressComponents = place.address_components;
                const cityComponents = addressComponents.find((component) =>
                  component.types.includes('locality')
                );
                const city = cityComponents ? cityComponents.long_name : '';
                console.log(city);
              }}
              placeholder="Enter City Name"
              options={{
                types: ['(cities)'],
                componentRestrictions: { country: 'ca' },
              }}
              className="search-input"
            />
            <button className="get-started-btn">Get Started</button>
          </SerchContainer>
        </div>
        {/* <div className="rightSection">
                    <img src={HeroImage} alt="HeroImage" />
                </div> */}
      </div>
      {/* <Vectors src={v1} alt="" />
            <Vectors src={v2} alt="" />
            <Vectors src={v3} alt="" />
            <Vectors src={v4} alt="" /> */}
    </Container>
  );
}
