import React, { useContext, useState } from 'react';
import {
  Container,
  Types,
  PriceSelector,
  SortByElements,
  ShareContainer,
} from './Filter.style';
import { NewConstructionContext } from '../../Context/Construction.context';
import FilterIcon from './Images/FilterIcon.svg';
import DropDown from './Images/DownIcon.svg';
import SortBy from './Images/SortBuy.svg';
import { SharePropertyContext } from '../../Context/ShareProperty.context';
import ShareForm from '../ShareForm/ShareForm.component';
import SearchCity from '../SearchCity/SearchCity.component';
export default function Filter() {
  const {
    HomeType,
    state,
    BuildingStatus,
    MaxPrice,
    MinPrice,
    Bathroom,
    Bedroom,
    sortBy,
    sortDir,
    Reset,
  } = useContext(NewConstructionContext);
  const ShareContext = useContext(SharePropertyContext);
  const [filterContainers, setFilterContainers] = useState('idle');
  const [shareContainer, setShareContainer] = useState('off');

  const handleFilter = () => {
    setFilterContainers((prev) => (prev === 'filter' ? 'idle' : 'filter'));
  };

  const handleSortBy = () => {
    setFilterContainers((prev) => (prev === 'sortBy' ? 'idle' : 'sortBy'));
  };

  const handleMaxMinPrice = (value) => {
    console.log(value);
    if (value === '1') {
      MinPrice('0');
      MaxPrice('500000');
    } else if (value === '2') {
      MinPrice('500000');
      MaxPrice('1000000');
    } else {
      MinPrice('1000000');
      MaxPrice('1500000');
    }
  };
  const handleShare = () => {
    setShareContainer((prev) => (prev === 'on' ? 'off' : 'on'));
  };
  return (
    <>
      <Container>
        <SearchCity />
        <div className="SortingContainers">
          <div className="SortingContainer">
            <div className="Filters" onClick={handleFilter}>
              <img src={FilterIcon} alt="FilterIcon" />
              <p>Filters</p>
              <img src={DropDown} alt="DropDown" />
            </div>
            {filterContainers === 'filter' && (
              <div className="FilterContainer">
                <div className="Filter">
                  <p className="Heading">Filters</p>
                  <p className="Reset" onClick={Reset}>
                    Reset Filters
                  </p>
                </div>
                <div className="HouseType">
                  <h1>House Type</h1>
                  <div className="Types">
                    <Types
                      value=""
                      check={state.home_type === ''}
                      onClick={(e) => HomeType(e.target.value)}
                    >
                      Any
                    </Types>
                    <Types
                      value="Detached"
                      check={state.home_type === 'Detached'}
                      onClick={(e) => HomeType(e.target.value)}
                    >
                      Detached
                    </Types>
                    <Types
                      value="Semi-detached"
                      check={state.home_type === 'Semi-detached'}
                      onClick={(e) => HomeType(e.target.value)}
                    >
                      Semi-Detached
                    </Types>
                    <Types
                      value="Twon House"
                      check={state.home_type === 'Town House'}
                      onClick={(e) => HomeType(e.target.value)}
                    >
                      Town House
                    </Types>
                    <Types
                      value="Condo-Twonhouse"
                      check={state.home_type === 'Condo-Twonhouse'}
                      onClick={(e) => HomeType(e.target.value)}
                    >
                      Condo Town House
                    </Types>
                    <Types
                      value="Condo-Apartment"
                      check={state.home_type === 'Condo-Apartment'}
                      onClick={(e) => HomeType(e.target.value)}
                    >
                      Condo Apartment
                    </Types>
                  </div>
                </div>
                <div className="BuildingStatus">
                  <h1>Building Status</h1>
                  <div className="Types">
                    <Types
                      value=""
                      check={state.building_status === ''}
                      onClick={(e) => BuildingStatus(e.target.value)}
                    >
                      Any
                    </Types>
                    <Types
                      value="pre-construction"
                      check={state.building_status === 'pre-construction'}
                      onClick={(e) => BuildingStatus(e.target.value)}
                    >
                      Pre-construction
                    </Types>
                    <Types
                      value="inventory"
                      check={state.building_status === 'inventory'}
                      onClick={(e) => BuildingStatus(e.target.value)}
                    >
                      Inventory
                    </Types>
                    <Types
                      value="assignmentsale"
                      check={state.building_status === 'assignmentsale'}
                      onClick={(e) => BuildingStatus(e.target.value)}
                    >
                      Assignment Sale
                    </Types>
                  </div>
                </div>
                <div className="PriceRange">
                  <h1>Price Range</h1>
                  <div className="Types">
                    <PriceSelector>
                      <div className="min_price">
                        <select
                          name=""
                          id=""
                          onChange={(e) => MinPrice(e.target.value)}
                        >
                          <option value="No min">No Min</option>
                          <option value="0">0</option>
                          <option value="500000">500</option>
                          <option value="1000000">1M</option>
                          <option value="1500000">1.5M</option>
                          <option value="2000000">2M</option>
                          <option value="3000000">3M</option>
                        </select>
                      </div>
                      <span>-</span>
                      <div className="max_price">
                        <select
                          name=""
                          id=""
                          onChange={(e) => MaxPrice(e.target.value)}
                        >
                          <option value="nomax">No Max</option>
                          <option value="0">0</option>
                          <option value="500000">500</option>
                          <option value="1000000">1M</option>
                          <option value="1500000">1.5M</option>
                          <option value="2000000">2M</option>
                          <option value="3000000">3M</option>
                        </select>
                      </div>
                    </PriceSelector>
                    <Types
                      value="1"
                      onClick={(e) => handleMaxMinPrice(e.target.value)}
                    >
                      0-500
                    </Types>
                    <Types
                      value="2"
                      onClick={(e) => handleMaxMinPrice(e.target.value)}
                    >
                      500-1M
                    </Types>
                    <Types
                      value="3"
                      onClick={(e) => handleMaxMinPrice(e.target.value)}
                    >
                      1M-1.5M
                    </Types>
                  </div>
                </div>
                <div className="Combo-bedBath">
                  <div className="BedRooms">
                    <h1>Bed Rooms</h1>
                    <div className="Types">
                      <Types
                        value=""
                        check={state.bedroom === ''}
                        onClick={(e) => Bedroom(e.target.value)}
                      >
                        Any
                      </Types>
                      <Types
                        value="1"
                        check={state.bedroom === '1'}
                        onClick={(e) => Bedroom(e.target.value)}
                      >
                        1+
                      </Types>
                      <Types
                        value="2"
                        check={state.bedroom === '2'}
                        onClick={(e) => Bedroom(e.target.value)}
                      >
                        2+
                      </Types>
                      <Types
                        value="3"
                        check={state.bedroom === '3'}
                        onClick={(e) => Bedroom(e.target.value)}
                      >
                        3+
                      </Types>
                      <Types
                        value="4"
                        check={state.bedroom === '4'}
                        onClick={(e) => Bedroom(e.target.value)}
                      >
                        4+
                      </Types>
                      <Types
                        value="5"
                        check={state.bedroom === '5'}
                        onClick={(e) => Bedroom(e.target.value)}
                      >
                        5+
                      </Types>
                    </div>
                  </div>
                  <div className="BathRooms">
                    <h1>Bath Rooms</h1>
                    <div className="Types">
                      <Types
                        value=""
                        check={state.bathroom === ''}
                        onClick={(e) => Bathroom(e.target.value)}
                      >
                        Any
                      </Types>
                      <Types
                        value="1"
                        check={state.bathroom === '1'}
                        onClick={(e) => Bathroom(e.target.value)}
                      >
                        1+
                      </Types>
                      <Types
                        value="2"
                        check={state.bathroom === '2'}
                        onClick={(e) => Bathroom(e.target.value)}
                      >
                        2+
                      </Types>
                      <Types
                        value="3"
                        check={state.bathroom === '3'}
                        onClick={(e) => Bathroom(e.target.value)}
                      >
                        3+
                      </Types>
                      <Types
                        value="4"
                        check={state.bathroom === '4'}
                        onClick={(e) => Bathroom(e.target.value)}
                      >
                        4+
                      </Types>
                      <Types
                        value="5"
                        check={state.bathroom === '5'}
                        onClick={(e) => Bathroom(e.target.value)}
                      >
                        5+
                      </Types>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="SortingContainer">
            <div className="Filters" onClick={handleSortBy}>
              <img src={SortBy} alt="SortByIcon" />
              <p>Sort Buy</p>
              <img src={DropDown} alt="DropDown" />
            </div>
            {filterContainers === 'sortBy' && (
              <div className="SortBy">
                <SortByElements
                  value="list_price"
                  data-sortDir="desc"
                  onClick={(e) => {
                    sortBy(e.target.value);
                    sortDir(e.currentTarget.getAttribute('data-sortDir'));
                  }}
                >
                  Recommended
                </SortByElements>
                <SortByElements
                  value="timestamp_idx"
                  data-sortDir="desc"
                  onClick={(e) => {
                    sortBy(e.target.value);
                    sortDir(e.currentTarget.getAttribute('data-sortDir'));
                  }}
                >
                  Most Recent
                </SortByElements>
                <SortByElements
                  value="list_price"
                  data-sortDir="desc"
                  onClick={(e) => {
                    sortBy(e.target.value);
                    sortDir(e.currentTarget.getAttribute('data-sortDir'));
                  }}
                >
                  Most Expensive
                </SortByElements>
                <SortByElements
                  value="timestamp_idx"
                  data-sortDir="asc"
                  onClick={(e) => {
                    sortBy(e.target.value);
                    sortDir(e.currentTarget.getAttribute('data-sortDir'));
                  }}
                >
                  Oldest
                </SortByElements>
                <SortByElements
                  value="list_price"
                  data-sortDir="asc"
                  onClick={(e) => {
                    sortBy(e.target.value);
                    sortDir(e.currentTarget.getAttribute('data-sortDir'));
                  }}
                >
                  Leaset Expensive
                </SortByElements>
                <SortByElements
                  value="bedrooms"
                  data-sortDir="desc"
                  onClick={(e) => {
                    sortBy(e.target.value);
                    sortDir(e.currentTarget.getAttribute('data-sortDir'));
                  }}
                >
                  Most Bedroom
                </SortByElements>
              </div>
            )}
          </div>
        </div>
        {ShareContext.state.markCheck && (
          <div className="ShareButton" onClick={handleShare}>
            Share {ShareContext.state.propertyList.length}
          </div>
        )}
      </Container>
      {shareContainer === 'on' && (
        <ShareContainer onClick={handleShare}>
          <ShareForm />
        </ShareContainer>
      )}
    </>
  );
}
