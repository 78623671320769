import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../components/DashBoard/Dashboard';
import ParentForm from '../components/Parent Form/ParentForm';
import ChildForm from '../components/Child Form/ChildForm';
import BuilderProperties from '../components/DashBoard/Components/BuilderProperties';
import UserDetails from '../components/DashBoard/Components/UserDetails/UserDetails';
import Builderlead from '../components/DashBoard/Components/BuilderLeads/Builderlead';
import BuilderDealers from '../components/DashBoard/Components/BuilderDealers/BuilderDealers';
import DashboardMessage from '../page/DashboardMessage';
import BuilderPropertyDetail from '../components/DashBoard/Components/BuilderPropertyDetail/BuilderPropertyDetail';
import BuilderPropertyUpdate from '../components/DashBoard/Components/BuilderPropertyUpdate/BuilderPropertyUpdate';

function DashboardRoute() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<DashboardMessage />} />
        <Route path="userdetails" element={<UserDetails />} />
        <Route path="properties" element={<BuilderProperties />} />
        <Route path="listing" element={<h2>All property listings.</h2>} />
        <Route path="clients" element={<h2>List of clients and details.</h2>} />
        <Route path="properties/parent-form" element={<ParentForm />} />
        <Route
          path="properties/builderpropertydetail/:id/builderproperty-update/:id"
          element={<BuilderPropertyUpdate />}
        />
        <Route
          path="properties/parent-form/child-form"
          element={<ChildForm />}
        />
        <Route path="builder-lead" element={<Builderlead />} />
        <Route path="builder-dealers" element={<BuilderDealers />} />
        <Route
          path="properties/builderpropertydetail/:id"
          element={<BuilderPropertyDetail />}
        />
      </Route>
      <Route
        path="properties/parent-form/child-form/:parentId"
        element={<ChildForm />}
      />
      <Route path="builder-lead" element={<Builderlead />} />
      <Route path="builder-dealers" element={<BuilderDealers />} />
    </Routes>
  );
}

export default DashboardRoute;
