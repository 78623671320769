import React, { useEffect, useState } from 'react';
import '../Search/search.css';
import arrow from '../Search/Image/arrow.svg';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { customAxios } from '../../config/axios';
import Autocomplete from 'react-google-autocomplete';

function Search() {
  const navigate = useNavigate();
  const [selectedHomeTypes, setSelectedHomeTypes] = useState([]);
  const [selectedAgentListings, setSelectedAgentListings] = useState(
    'Agent Listings(500+)'
  );
  const [selectedPrice, setSelectedPrice] = useState('Any Price');
  const [selectedBeds, setSelectedBeds] = useState('All Beds');
  const [selectedMoreFilters, setSelectedMoreFilters] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [childProperties, setChildProperties] = useState([]);
  const [parentProperties, setParentProperties] = useState([]);
  const [searchCity, setSearchCity] = useState('Toronto');
  const [city, setCity] = useState('');

  const handleCityChange = (e) => {
    const inputValue = e.target.value.split(',')[0];
    setSearchCity(inputValue.trim());
  };

  const handleHomeTypeChange = (e) => {
    const value = e.target.value;
    setSelectedHomeTypes((prevState) =>
      prevState.includes(value)
        ? prevState.filter((item) => item !== value)
        : [...prevState, value]
    );
  };

  const handleMoreFilterChange = (e) => {
    const value = e.target.value;
    setSelectedMoreFilters((prevState) =>
      prevState.includes(value)
        ? prevState.filter((item) => item !== value)
        : [...prevState, value]
    );
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get('/search/filter', {
          params: {
            propertytype: selectedHomeTypes.join(','),
            agentListings: selectedAgentListings,
            price: selectedPrice,
            beds: selectedBeds,
            filters: selectedMoreFilters.join(','),
            city: searchCity,
          },
          headers: {
            'x-access-token': token,
          },
        });

        if (response.data.status) {
          setChildProperties(response.data.data.childProperties || []);
          setParentProperties(response.data.data.parentProperties || []);
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchSearchResults();
  }, [
    selectedHomeTypes,
    selectedAgentListings,
    selectedPrice,
    selectedBeds,
    selectedMoreFilters,
    searchCity,
  ]);

  const toggleDropdown = (dropdown) => {
    const content = document.querySelector(`.${dropdown}-options`);
    content.style.display =
      content.style.display === 'block' ? 'none' : 'block';
  };

  const handleNavigate = () => {
    navigate('/map-search');
  };
  return (
    <>
      <div className="filter-bar">
        {/* <input
          type="text"
          className="search-input"
          placeholder="Toronto, Canada" value={searchCity}
          onChange={handleCityChange}
        /> */}

        <Autocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={(place) => {
            if (place.address_components) {
              const cityComponent = place.address_components.find((comp) =>
                comp.types.includes('locality')
              );
              if (cityComponent) {
                setSearchCity(cityComponent.long_name);
              }
            }
          }}
          options={{
            types: ['(cities)'],
            componentRestrictions: { country: 'ca' },
          }}
          placeholder="Enter city"
          className="search-input"
          value={searchCity}
          onChange={handleCityChange}
          style={{ width: '300px', padding: '8px' }}
        />
        {city && <p>Selected City: {city}</p>}

        <button className="search-button">
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-width="2"
              d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
            />
          </svg>
        </button>

        <button onClick={handleNavigate} className="search-button">
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"
            />
          </svg>
        </button>

        {/* Custom Dropdown for Agent Listings */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('agent-listings')}
          >
            {selectedAgentListings}
          </button>
          <div className="dropdown-options agent-listings-options">
            <label>
              <input
                type="radio"
                name="agent-listings"
                value="Agent Listings(500+) "
                checked={selectedAgentListings === 'Agent Listings(500+)'}
                onChange={(e) => setSelectedAgentListings(e.target.value)}
              />
              Agent Listings(500+)
            </label>
            <label>
              <input
                type="radio"
                name="agent-listings"
                value="Agent Listings(1000+)"
                checked={selectedAgentListings === 'Agent Listings(1000+)'}
                onChange={(e) => setSelectedAgentListings(e.target.value)}
              />
              Agent Listings(1000+)
            </label>
          </div>
        </div>

        {/* Custom Dropdown for Price */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('price')}
          >
            {selectedPrice}
          </button>
          <div className="dropdown-options price-options">
            <label>
              <input
                type="radio"
                name="price"
                value="all"
                checked={selectedPrice === 'all'}
                onChange={(e) => setSelectedPrice(e.target.value)}
              />
              Any Price
            </label>
            <label>
              <input
                type="radio"
                name="price"
                value="below500000"
                checked={selectedPrice === 'below500000'}
                onChange={(e) => setSelectedPrice(e.target.value)}
              />
              Below $500,000
            </label>
            <label>
              <input
                type="radio"
                name="price"
                value="above500000"
                checked={selectedPrice === 'above500000'}
                onChange={(e) => setSelectedPrice(e.target.value)}
              />
              Above $500,000
            </label>
          </div>
        </div>

        {/* Custom Dropdown for Beds */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('beds')}
          >
            {selectedBeds}
          </button>
          <div className="dropdown-options beds-options">
            <label>
              <input
                type="radio"
                name="beds"
                value="all"
                checked={selectedBeds === 'All Beds'}
                onChange={(e) => setSelectedBeds(e.target.value)}
              />
              All Beds
            </label>
            <label>
              <input
                type="radio"
                name="beds"
                value="1"
                checked={selectedBeds === '1'}
                onChange={(e) => setSelectedBeds(e.target.value)}
              />
              1 Bed
            </label>
            <label>
              <input
                type="radio"
                name="beds"
                value="2"
                checked={selectedBeds === '2'}
                onChange={(e) => setSelectedBeds(e.target.value)}
              />
              2 Beds
            </label>

            <label>
              <input
                type="radio"
                name="beds"
                value="3"
                checked={selectedBeds === '3'}
                onChange={(e) => setSelectedBeds(e.target.value)}
              />
              3 Beds
            </label>
          </div>
        </div>

        {/* Custom Dropdown for Home Types (Checkboxes) */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('home-types')}
          >
            {selectedHomeTypes.length
              ? selectedHomeTypes.join(', ')
              : 'Select Home Types'}
          </button>
          <div className="dropdown-options home-types-options">
            <label>
              <input
                type="checkbox"
                value="Detached"
                checked={selectedHomeTypes.includes('Detached')}
                onChange={handleHomeTypeChange}
              />
              Detached
            </label>
            <label>
              <input
                type="checkbox"
                value="Semi-Detached"
                checked={selectedHomeTypes.includes('Semi-Detached')}
                onChange={handleHomeTypeChange}
              />
              Semi-Detached
            </label>
            <label>
              <input
                type="checkbox"
                value="Twon House"
                checked={selectedHomeTypes.includes('Twon House')}
                onChange={handleHomeTypeChange}
              />
              Twon House
            </label>
            <label>
              <input
                type="checkbox"
                value="Condo-Apartment"
                checked={selectedHomeTypes.includes('Condo-Apartment')}
                onChange={handleHomeTypeChange}
              />
              Condo-Apartment
            </label>
            <label>
              <input
                type="checkbox"
                value="Condo-Twonhouse"
                checked={selectedHomeTypes.includes('Condo-Twonhouse')}
                onChange={handleHomeTypeChange}
              />
              Condo-Twonhouse
            </label>
          </div>
        </div>

        {/* Custom Dropdown for More Filters */}
        <div className="custom-dropdown">
          <button
            className="dropdown-btn"
            onClick={() => toggleDropdown('more-filters')}
          >
            More Filters
          </button>
          <div className="dropdown-options more-filters-options">
            <label>
              <input
                type="checkbox"
                value="all"
                checked={selectedMoreFilters.includes('all')}
                onChange={handleMoreFilterChange}
              />
              All
            </label>
            <label>
              <input
                type="checkbox"
                value="For Sale By Agent"
                checked={selectedMoreFilters.includes('For Sale By Agent')}
                onChange={handleMoreFilterChange}
              />
              For Sale By Agent
            </label>
            <label>
              <input
                type="checkbox"
                value="For Sale By Owner"
                checked={selectedMoreFilters.includes('For Sale By Owner')}
                onChange={handleMoreFilterChange}
              />
              For Sale By Owner
            </label>
            <label>
              <input
                type="checkbox"
                value="New Construction"
                checked={selectedMoreFilters.includes('New Construction')}
                onChange={handleMoreFilterChange}
              />
              New Construction
            </label>
            <label>
              <input
                type="checkbox"
                value="New Listings (Past Week)"
                checked={selectedMoreFilters.includes(
                  'New Listings (Past Week)'
                )}
                onChange={handleMoreFilterChange}
              />
              New Listings (Past Week)
            </label>
          </div>
        </div>
      </div>

      {/* cards */}

      <div className="search-container-flex" style={{ padding: '1rem' }}>
        <div className="row">
          {[...childProperties, ...parentProperties].length > 0 ? (
            [...childProperties, ...parentProperties].map((property) => (
              <div key={property._id} className="col-sm-6 col-lg-3 mt-4">
                {/* Card component */}
                <div className="search-altman-properties-item">
                  <a
                    href="#"
                    title={`Property in ${property.city}`}
                    tabIndex="-1"
                  >
                    <div className="search-altman-properties-item-inner-wrapper">
                      <div className="search-altman-properties-item-photo">
                        <canvas
                          width="529"
                          height="460"
                          className="search-lazy-load-canvas-element"
                          style={{
                            backgroundImage: `url("${
                              property.communityImages?.[0] 
                                ? `${process.env.REACT_APP_API_URL}/static/${property.communityImages[0]}` 
                                : "https://images.pexels.com/photos/6074129/pexels-photo-6074129.jpeg?auto=compress&cs=tinysrgb&w=600"
                            }")`,                          }}
                        ></canvas>
                      </div>
                      <div className="search-altman-properties-item-content-status-city search-ease">
                        <div>{property.status || 'Available'}</div>
                        <div>{property.city || 'Unknown City'}</div>
                      </div>
                      <div className="search-altman-properties-item-content search-ease">
                        <div className="search-altman-properties-item-content-address">
                          {property.projectname}
                          <div>{property.city || 'Unknown City'}</div>
                        </div>
                        <ul className="search-altman-properties-item-content-features search-ease search-ps-0">
                          <li>{property.bed || 0} BD</li>
                          <li>{property.bath || 0} BA</li>
                          <li>{property.squarefeet} sqft</li>
                        </ul>
                        <div className="search-altman-properties-item-content-price search-ease">
                          {property.price
                            ? `$${property.price}`
                            : 'Price Not Available'}
                        </div>
                      </div>
                      <div className="search-altman-properties-item-link-label search-ease">
                        View Details
                      </div>
                    </div>
                  </a>
                </div>
                {/* Card component end */}
              </div>
            ))
          ) : (
            <p>No properties found.</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Search;
