/** @format */

import React from 'react';

import CityGrid from '../components/CityGridHome/CityGrid';
import Featured from '../components/Featured/Featured.component';
import HeroHome from '../components/HeroHome/HeroHome.component';
import BuilderMatchHelpSection from '../components/RedBuyersHelpSection/RedBuyersHelpSection.component';
import Preconstructioncard from '../components/Pre-Construction/Preconstructioncard';
import InventoryCard from '../components/InventorySale/InventoryCard';
import AssignmentSaleCard from '../components/AssignmentSale/AssignmentSaleCard';

export default function Home() {
  return (
    <>
      <HeroHome />
      <BuilderMatchHelpSection />
      <Preconstructioncard />
      <InventoryCard />
      <AssignmentSaleCard />
      {/* <Featured /> */}
      <CityGrid />
    </>
  );
}
