import './builderpropertydetail.css';
import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { useNavigate, useParams } from 'react-router-dom';
import name from './images/name.svg';
import sale from './images/sale.svg';
import builder from './images/builder.svg';
import construction from './images/construction.svg';
import sqft from './images/sqft.svg';
import garage from './images/garage.svg';
import location from './images/ei_location.svg';
import style from './images/style .svg';
import types from './images/Type.svg';
import parking from './images/parking-sign.svg';
import bed from './images/bed.svg';
import bath from './images/bath.svg';
import garage2 from './images/garage2.svg';
import sqft2 from './images/sqft2.svg';
import frontlength from './images/vector.png';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { customAxios } from '../../../../config/axios';
import Viewer from 'viewerjs';

function BuilderPropertyDetail() {
  const { id } = useParams();
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [childproperty, setchildproperty] = useState([]);
  const [activeTab, setActiveTab] = useState('builderDescription');

  const viewerRef = useRef(null);
  const [renderViewer, setRenderViewer] = useState(false);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const openGallery = () => {
    setRenderViewer(!renderViewer);
  };
  const sliderSettings = {
    dots: true, // Enable navigation dots
    infinite: true, // Infinite loop for sliding
    speed: 500, // Speed of the transition
    slidesToShow: 1, // Show 1 slide at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Auto-play the slider
    autoplaySpeed: 3000, // Interval time for auto-play
  };

  useEffect(() => {
    const fetchBuilderData = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get(
          `/builderparent/viewbyid-parentproperty/${id}`,
          {
            headers: { 'x-access-token': token },
          }
        );

        console.log('API Response:', response.data);

        if (response.data.status) {
          setPropertyDetails(response.data.data);
        } else {
          setPropertyDetails(null);
        }
      } catch (error) {
        console.error('Error fetching builder data:', error);
        setPropertyDetails(null);
      }
    };

    fetchBuilderData();
  }, [id]);

  const navigate = useNavigate();

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this property?'
    );
    if (!confirmDelete) return;

    try {
      const token = Cookies.get('token');
      const response = await customAxios.delete(
        `/builderparent/delete-parentproperty/${id}`,
        {
          headers: { 'x-access-token': token },
        }
      );

      if (response.data.status) {
        navigate('/dashboard/builder/properties');
        toast.success('Property deleted successfully!');
      } else {
        toast.error('Failed to delete property!');
      }
    } catch (error) {
      console.error('Error deleting property:', error);
      alert('An error occurred while deleting the property.');
    }
  };

  // Navigate to Update Page
  const handleNavigate = () => {
    navigate(`builderproperty-update/${id}`);
  };

  useEffect(() => {
    const fetchchildData = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get(
          `/builderchild/view-childpropetybyparent/${id}`,
          {
            headers: { 'x-access-token': token },
          }
        );

        console.log('API Response:', response.data);

        if (response.data.status) {
          setchildproperty(response.data.data || []);
        } else {
          setchildproperty([]);
        }
      } catch (error) {
        console.error('Error fetching builder data:', error);
        setchildproperty([]);
      }
    };

    fetchchildData();
  }, [id]);

  useEffect(() => {
    let viewer;
    if (renderViewer && viewerRef.current) {
      viewer = new Viewer(viewerRef.current, {
        hidden() {
          setRenderViewer(false);
        },
      });
      viewer.view();
    }
    return () => {
      if (viewer) {
        viewer.destroy();
      }
    };
  }, [renderViewer]);
  return (
    <>
      {propertyDetails ? (
        <div>
          <div className="builder-dashboard-Slider">
            <Slider {...sliderSettings}>
              {(propertyDetails.buildingImages &&
              propertyDetails.buildingImages.length >= 0
                ? propertyDetails.buildingImages
                : [
                    'https://images.pexels.com/photos/18038074/pexels-photo-18038074/free-photo-of-house-with-garden.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
                  ]
              ).map((image, index) => (
                <img
                  height={'100%'}
                  width={'100%'}
                  src={`${process.env.REACT_APP_API_URL}/static/${image}`}
                  alt={`Home Cover ${index + 1}`}
                  onError={(e) =>
                    (e.target.src =
                      'https://images.pexels.com/photos/18038074/pexels-photo-18038074/free-photo-of-house-with-garden.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load')
                  }
                />
              ))}
            </Slider>

            <div
              className="builder-slider_button"
              onClick={() => openGallery()}
            >
              <p>+ show all photos</p>
            </div>

            <div className="no_slider" ref={viewerRef}>
              {propertyDetails.buildingImages?.map((data) => (
                <img
                  key={data}
                  src={`${process.env.REACT_APP_API_URL}/static/${data}`}
                  alt="HomeCovers"
                />
              ))}
            </div>
            <div className="builder-header-content">
              <div className="builder-part-1">
                <h1 style={{ fontWeight: 'bold' }}>
                  {propertyDetails.projectname}
                </h1>
                <p>{propertyDetails.projectaddress}</p>
              </div>
              <div className="builder-part-2">
                <h1 style={{ fontWeight: 'bold' }}>
                  $-{propertyDetails.price}
                </h1>
                <p>{propertyDetails.squarefeet}/sq.ft</p>
              </div>
              <button className="builder-update-btn" onClick={handleNavigate}>
                Update
              </button>
              <button className="builder-delete-btn" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>

          <div className="builder-property-container">
            <div className="builder-property-item">
              <img src={bed} alt="" className="builder-property-icon" />
              <span>{propertyDetails.bed} Beds</span>
            </div>
            <div className="builder-property-item">
              <img src={bath} alt="" className="builder-property-icon" />
              <span>{propertyDetails.bath} Baths</span>
            </div>
            <div className="builder-property-item">
              <img src={garage2} alt="" className="builder-property-icon" />
              <span>{propertyDetails.parking} Garage</span>
            </div>
            <div className="builder-property-item">
              <img src={sqft2} alt="" className="builder-property-icon" />
              <span>{propertyDetails.squarefeet} Sq.ft</span>
            </div>
            <div className="builder-property-item">
              <img
                src={frontlength}
                alt=""
                width={'1rem'}
                className="builder-property-icon"
              />
              <span>{propertyDetails.lotsize} m Length</span>
            </div>
          </div>

          <div class="builder-section-2">
            <div class="builder-content">
              <div class="builder-description">
                <h2 style={{ fontWeight: 'bold' }}>Description</h2>
                <p>{propertyDetails.projectdescription}</p>
              </div>

              <div class="builder-project-highlights">
                <h2 style={{ fontWeight: 'bold' }}>Project Highlight</h2>
                <div class="builder-highlight-grid">
                  <div>
                    <img src={name} alt="" />
                    <strong>Project Name</strong>
                    <span>{propertyDetails.projectname}</span>
                  </div>
                  <div>
                    <img src={builder} alt="" />
                    <strong>Builder Name</strong>
                    <span>{propertyDetails.buildername}</span>
                  </div>
                  <div>
                    <img src={sale} alt="" />
                    <strong>Sale Type</strong>
                    <span>{propertyDetails.typeofsale}</span>
                  </div>
                  <div>
                    <img src={construction} alt="" />
                    <strong>Construction Year</strong>
                    <span>{propertyDetails.completionyear}</span>
                  </div>
                  <div>
                    <img src={sqft} alt="" />
                    <strong>Price/SqFt</strong>
                    <span>{propertyDetails.price} $</span>
                  </div>
                  <div>
                    <img src={parking} alt="" />
                    <strong>Parking</strong>
                    <span>{propertyDetails.parking}</span>
                  </div>
                  <div>
                    <img src={types} alt="" />
                    <strong>Type</strong>
                    <span>{propertyDetails.housestyle}</span>
                  </div>
                  <div>
                    <img src={garage} alt="" />
                    <strong>Garage</strong>
                    <span>{propertyDetails.garage}</span>
                  </div>
                  <div>
                    <img src={location} alt="" />
                    <strong>City</strong>
                    <span>{propertyDetails.city}</span>
                  </div>
                  <div>
                    <img src={style} alt="" />
                    <strong>Style</strong>
                    <span>{propertyDetails.housestyle}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="builder-contact-agent">
              <div className="builder-contact-form">
                <h2>Contact agent</h2>
                <div className="builder-agent-info">
                  <img src="profile-placeholder.png" alt="Agent" />
                  <div className="builder-agent-details">
                    <h3>{propertyDetails.userId.name}</h3>
                    <p>{propertyDetails.userId.personalPhone}</p>
                    <p>{propertyDetails.userId.email}</p>
                  </div>
                </div>
                <form className="builder-contact-form-fields">
                  <input
                    className="builder-input-field"
                    type="text"
                    placeholder="Your name"
                    required
                  />
                  <input
                    className="builder-input-field"
                    type="email"
                    placeholder="Your mail"
                    required
                  />
                  <input
                    className="builder-input-field"
                    type="tel"
                    placeholder="Your phone"
                    required
                  />
                  <textarea
                    className="builder-textarea-field"
                    placeholder="Your message"
                  ></textarea>
                  <button className="builder-submit-button" type="submit">
                    Send message
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className="builder-section-4">
            {/* Tab Buttons */}
            <div className="builder-tabs">
              <button
                className={`builder-tab-button ${
                  activeTab === 'builderDescription' ? 'active' : ''
                }`}
                onClick={() => handleTabClick('builderDescription')}
              >
                Builder Description
              </button>
              <button
                className={`builder-tab-button ${
                  activeTab === 'communityDescription' ? 'active' : ''
                }`}
                onClick={() => handleTabClick('communityDescription')}
              >
                Community Description
              </button>
              <button
                className={`builder-tab-button ${activeTab === 'layouts' ? 'active' : ''}`}
                onClick={() => handleTabClick('layouts')}
              >
                Layouts
              </button>
              <button
                className={`builder-tab-button ${
                  activeTab === 'virtualTour' ? 'active' : ''
                }`}
                onClick={() => handleTabClick('virtualTour')}
              >
                Virtual Tour
              </button>
            </div>

            {/* Tab Content */}
            <div className="builder-tab-content">
              {activeTab === 'builderDescription' && (
                <div>
                  <h2 style={{ fontWeight: 'bold' }}>Builder Description</h2>
                  <p>{propertyDetails.builderdescription}</p>
                </div>
              )}

              {activeTab === 'communityDescription' && (
                <div>
                  <h2 style={{ fontWeight: 'bold' }}>Community Description</h2>
                  <p>{propertyDetails.communitydescription}</p>
                </div>
              )}

              {activeTab === 'layouts' && (
                <div>
                  <h2 style={{ fontWeight: 'bold' }}>Layouts</h2>
                  <img
                    src={propertyDetails.communityImages}
                    alt="communityImages"
                    width={'30rem'}
                    height={'30rem'}
                  />
                </div>
              )}

              {activeTab === 'virtualTour' && (
                <div>
                  <h2 style={{ fontWeight: 'bold' }}>Virtual Tour</h2>
                  <p>
                    Morbi mauris nunc sit aliquet. Phasellus et vulputate neque
                    porttitor nec sit tempor porta.
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="builder-section-3">
            <div className="builder-map-proerty-main">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d36553983.44087083!2d-96!3d56!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sCanada!5e0!3m2!1sen!2sin!4v1737444920786!5m2!1sen!2sin"
                className="map-view-proerty-main"
                width={'100%'}
                height={'100%'}
              ></iframe>
            </div>

            <div className="builder-tour-proerty-main">
              <div className="builder-tour-box">
                <h2>
                  Request a tour as early as <br />{' '}
                  <strong>Today at 7:00 PM</strong>
                </h2>
                <button class="builder-tour-button">Schedule Tour</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading property details...</p>
      )}

      <div className="builder-section-5">
        <h2 style={{ fontWeight: 'bold' }}>
          All New Homes in Wright Farms: Cottage Collection
        </h2>
        <p>Quick Move-in Homes</p>
        <table className="builder-table">
          <thead>
            <tr>
              <th>Count</th>
              <th>Project Name</th>
              <th>City</th>
              <th>Style</th>
              <th>Area (sqft)</th>
              <th>Model</th>
            </tr>
          </thead>
          <tbody>
            {childproperty.map((child, index) => (
              <tr key={child._id}>
                <td>{index + 1}</td>
                <td>
                  <Link to={`/new-properties/${child._id}`}>
                    {child.projectname}
                  </Link>
                </td>
                <td>{child.city}</td>
                <td>{child.housestyle}</td>
                <td>{child.squarefeet}</td>
                <td>{child.modelname}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default BuilderPropertyDetail;
