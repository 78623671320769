import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Link, useNavigate, useParams } from 'react-router-dom';
import name from './images/name.svg';
import sale from './images/sale.svg';
import builder from './images/builder.svg';
import construction from './images/construction.svg';
import sqft from './images/sqft.svg';
import garage from './images/garage.svg';
import location from './images/ei_location.svg';
import style from './images/style .svg';
import types from './images/Type.svg';
import parking from './images/parking-sign.svg';
import bed from './images/bed.svg';
import bed1 from './images/bed.svg';
import bath from './images/bath.svg';
import bath1 from './images/bath.svg';
import garage2 from './images/garage2.svg';
import sqft2 from './images/sqft2.svg';
import frontlength from './images/vector.png';
import Cookies from 'js-cookie';
import './sellerpropertydetail.css';
import { toast } from 'react-toastify';
import { customAxios } from '../../../../config/axios';

function SellerPropertyDetail() {
  const [activeTab, setActiveTab] = useState('builderDescription');
  const { id } = useParams();
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [sellerproperty, setsellerproperty] = useState([]);
  const navigate = useNavigate();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const sliderSettings = {
    dots: true, // Enable navigation dots
    infinite: true, // Infinite loop for sliding
    speed: 500, // Speed of the transition
    slidesToShow: 1, // Show 1 slide at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Auto-play the slider
    autoplaySpeed: 3000, // Interval time for auto-play
  };

  useEffect(() => {
    const fetchBuilderData = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get(
          `/seller/viewbyid-sellerprperty/${id}`,
          {
            headers: { 'x-access-token': token },
          }
        );

        console.log('API Response:', response.data); // Log the response

        if (response.data.status) {
          setPropertyDetails(response.data.data); // Set the property details
        } else {
          setPropertyDetails(null); // Set null if no valid data
        }
      } catch (error) {
        console.error('Error fetching builder data:', error);
        setPropertyDetails(null); // Set null in case of error
      }
    };

    fetchBuilderData();
  }, [id]);

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this property?'
    );
    if (!confirmDelete) return;

    try {
      const token = Cookies.get('token');
      const response = await customAxios.delete(
        `/seller/delete-sellerproperty/${id}`,
        {
          headers: { 'x-access-token': token },
        }
      );

      if (response.data.status) {
        navigate('/dashboard/seller/seller-properties');
        toast.success('Property deleted successfully!');
      } else {
        toast.error('Failed to delete property!');
      }
    } catch (error) {
      console.error('Error deleting property:', error);
      alert('An error occurred while deleting the property.');
    }
  };

  // Navigate to Update Page
  const handleNavigate = () => {
    navigate(`seller-property-update/${id}`);
  };

  useEffect(() => {
    const fetchchildData = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get(
          `/seller/viewall-sellerproperty/`,
          {
            headers: { 'x-access-token': token },
          }
        );

        console.log('API Response:', response.data);

        if (response.data.status) {
          setsellerproperty(response.data.data || []);
        } else {
          setsellerproperty([]);
        }
      } catch (error) {
        console.error('Error fetching builder data:', error);
        setsellerproperty([]);
      }
    };

    fetchchildData();
  }, [id]);

  return (
    <>
      {propertyDetails ? (
        <div>
          <div className="Slider" style={{ width: '100%' }}>
            <Slider {...sliderSettings}>
              {/* Use map to render multiple images */}
              {(propertyDetails.unitImages &&
              propertyDetails.unitImages.length > 0
                ? propertyDetails.unitImages
                : [
                    'https://images.pexels.com/photos/18038074/pexels-photo-18038074/free-photo-of-house-with-garden.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
                  ]
              ).map((image, index) => (
                <div key={index}>
                  <img
                    height={'50rem'}
                    src={image}
                    alt={`Home Cover ${index + 1}`}
                    onError={(e) =>
                      (e.target.src =
                        'https://images.pexels.com/photos/18038074/pexels-photo-18038074/free-photo-of-house-with-garden.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load')
                    }
                  />
                </div>
              ))}
            </Slider>

            <div
              className="slider_button"
              onClick={() => console.log('Show all 15 photos')}
            >
              <p>+ show all 15 photos</p>
            </div>
            <div className="header-content">
              <div className="part-1">
                <h1 style={{ fontWeight: 'bold' }}>
                  {propertyDetails.projectaddress}
                </h1>
                <p>{propertyDetails.city}</p>
              </div>
              <div className="part-2">
                <h1 style={{ fontWeight: 'bold' }}>${propertyDetails.price}</h1>
                <p>${propertyDetails.squarefeet}/sq.ft</p>
              </div>
            </div>
          </div>

          <div className="seller-pdetail-property-container">
            <div className="seller-pdetail-property-item">
              <img src={bed} alt="" className="seller-pdetail-property-icon" />
              <span>{propertyDetails.bed} Beds</span>
            </div>
            <div className="seller-pdetail-property-item">
              <img src={bath} alt="" className="seller-pdetail-property-icon" />
              <span>{propertyDetails.bath} Baths</span>
            </div>
            <div className="seller-pdetail-property-item">
              <img
                src={garage2}
                alt=""
                className="seller-pdetail-property-icon"
              />
              <span>{propertyDetails.garage} Garage</span>
            </div>
            <div className="seller-pdetail-property-item">
              <img
                src={sqft2}
                alt=""
                className="seller-pdetail-property-icon"
              />
              <span>{propertyDetails.squarefeet} Sq.ft</span>
            </div>
            <div className="seller-pdetail-property-item">
              <img
                src={frontlength}
                alt=""
                width={'1rem'}
                className="seller-pdetail-property-icon"
              />
              <span>{propertyDetails.lotsize} m Length</span>
            </div>
          </div>

          <div class="seller-pdetail-description">
            <h2 style={{ fontWeight: 'bold' }}>Description</h2>
            <p>{propertyDetails.unitdescription}</p>
          </div>

          <div class="seller-pdetail-section-2">
            <div class="seller-pdetail-content">
              <div class="seller-pdetail-project-highlights">
                <h2 style={{ fontWeight: 'bold' }}>Project Highlight</h2>
                <div class="seller-pdetail-highlight-grid">
                  <div>
                    <img src={sqft} alt="" />
                    <strong>Total Area</strong>
                    <span>{propertyDetails.squarefeet} Sqft</span>
                  </div>
                  <div>
                    <img src={parking} alt="" />
                    <strong>Parking</strong>
                    <span>{propertyDetails.parking}</span>
                  </div>
                  <div>
                    <img src={bed1} alt="" />
                    <strong>Bedrooms</strong>
                    <span>{propertyDetails.bed}</span>
                  </div>
                  <div>
                    <img src={construction} alt="" />
                    <strong>Construction Year</strong>
                    {/* <span>{propertyDetails.completionyear}</span> */}
                  </div>
                  <div>
                    <img src={bath1} alt="" />
                    <strong>Bathrooms</strong>
                    <span>{propertyDetails.bath}</span>
                  </div>
                  <div>
                    <img src={builder} alt="" />
                    <strong>Builder Name</strong>
                    <span>{propertyDetails.buildername}</span>
                  </div>
                  <div>
                    <img src={name} alt="" />
                    <strong>Project Name</strong>
                    <span>{propertyDetails.projectname}</span>
                  </div>
                  <div>
                    <img src={garage} alt="" />
                    <strong>Garage</strong>
                    <span>{propertyDetails.garage}</span>
                  </div>
                  <div>
                    <img src={sale} alt="" />
                    <strong>Sale Type</strong>
                    <span>{propertyDetails.typeofsale}</span>
                  </div>
                  <div>
                    <img src={style} alt="" />
                    <strong>Style</strong>
                    <span>{propertyDetails.housestyle}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="seller-pdetail-tour">
              <div className="seller-pdetail-tour-box">
                <h2>
                  Request a tour as early as <br />{' '}
                  <strong>Today at 7:00 PM</strong>
                </h2>
                <button class="seller-pdetail-tour-button">
                  Schedule Tour
                </button>
              </div>
            </div>
          </div>
          {/* 
        <div className="section-4">
          <div className="tabs">
            <button
              className={`tab-button ${
                activeTab === "builderDescription" ? "active" : ""
              }`}
              onClick={() => handleTabClick("builderDescription")}
            >
              Builder Description
            </button>
            <button
              className={`tab-button ${
                activeTab === "communityDescription" ? "active" : ""
              }`}
              onClick={() => handleTabClick("communityDescription")}
            >
              Community Description
            </button>
            <button
              className={`tab-button ${
                activeTab === "layouts" ? "active" : ""
              }`}
              onClick={() => handleTabClick("layouts")}
            >
              Layouts
            </button>
            <button
              className={`tab-button ${
                activeTab === "virtualTour" ? "active" : ""
              }`}
              onClick={() => handleTabClick("virtualTour")}
            >
              Virtual Tour
            </button>
          </div>

          <div className="tab-content">
            {activeTab === "builderDescription" && (
              <div>
                <h2 style={{ fontWeight: "bold" }}>Builder Description</h2>
                <p>{propertyDetails.parentId.builderdescription}</p>
              </div>
            )}

            {activeTab === "communityDescription" && (
              <div>
                <h2 style={{ fontWeight: "bold" }}>Community Description</h2>
                <p>{propertyDetails.parentId.communitydescription}</p>
              </div>
            )}

            {activeTab === "layouts" && (
              <div>
                <h2 style={{ fontWeight: "bold" }}>Layouts</h2>
                <img
                  src={propertyDetails.parentId.communityImages}
                  alt="communityImages"
                  width={"30rem"}
                  height={"30rem"}
                />
              </div>
            )}

            {activeTab === "virtualTour" && (
              <div>
                <h2 style={{ fontWeight: "bold" }}>Virtual Tour</h2>
                <p>
                  Morbi mauris nunc sit aliquet. Phasellus et vulputate neque
                  porttitor nec sit tempor porta.
                </p>
              </div>
            )}
          </div>
        </div> */}

          <div className="seller-pdetail-section-3">
            <div className="seller-pdetail-map">
              <h2 style={{ fontWeight: 'bold' }}>Map</h2>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d36553983.44087083!2d-96!3d56!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sCanada!5e0!3m2!1sen!2sin!4v1737444920786!5m2!1sen!2sin"
                className="map-view"
                width={'100%'}
                height={'100%'}
              ></iframe>
            </div>

            <div className="seller-pdetail-contact-agent">
              <div className="seller-pdetail-contact-form">
                <h2>Contact agent</h2>
                <div className="seller-pdetail-agent-info">
                  <img src="profile-placeholder.png" alt="Agent" />
                  <div className="seller-pdetail-agent-details">
                    <h3>{propertyDetails.userId.name}</h3>
                    <p>{propertyDetails.userId.personalPhone}</p>
                    <p>{propertyDetails.userId.email}</p>
                  </div>
                </div>
                <form className="seller-pdetail-contact-form-fields">
                  <input
                    className="seller-pdetail-input-field"
                    type="text"
                    placeholder="Your name"
                    required
                  />
                  <input
                    className="seller-pdetail-input-field"
                    type="email"
                    placeholder="Your mail"
                    required
                  />
                  <input
                    className="seller-pdetail-input-field"
                    type="tel"
                    placeholder="Your phone"
                    required
                  />
                  <textarea
                    className="seller-pdetail-textarea-field"
                    placeholder="Your message"
                  ></textarea>
                  <button
                    className="seller-pdetail-submit-button"
                    type="submit"
                  >
                    Send message
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className="seller-pdetail-home-details-container">
            <h2 className="seller-pdetail-home-header">
              Home Details for{propertyDetails.address}
            </h2>

            <section className="seller-pdetail-section property-info">
              <h3 className="seller-pdetail-info-header">
                {' '}
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Property Information
              </h3>
              <div className="seller-pdetail-info-grid">
                <div>
                  <strong>Property Type:</strong> {propertyDetails.propertytype}
                </div>
                <div>
                  <strong>Year Built:</strong> {propertyDetails.completionyear}
                </div>
                <div>
                  <strong>Number of Bathrooms:</strong> {propertyDetails.bath}
                </div>
                <div>
                  <strong>Number of Bedrooms:</strong> {propertyDetails.bed}
                </div>
              </div>
              <div className="seller-pdetail-info-grid">
                <div>
                  <strong>Parking:</strong> {propertyDetails.parking}
                </div>
                <div>
                  <strong>Parking Spaces:</strong> 4
                </div>
                <div>
                  <strong>Number of Garage Spaces:</strong>{' '}
                  {propertyDetails.garage}
                </div>
                <div>
                  <strong>Open Parking Spaces:</strong> 2
                </div>
                <div>
                  <strong>Lot size:</strong> {propertyDetails.lotsize}
                </div>
                <div>
                  <strong>Property Style:</strong> {propertyDetails.housestyle}
                </div>
              </div>
            </section>

            <section className="seller-pdetail-section price-status">
              <h3 className="seller-pdetail-info-header">
                {' '}
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                </svg>
                Price & Status
              </h3>
              <div className="seller-pdetail-info-grid">
                <div>
                  <strong>List Price:</strong> ${propertyDetails.price}
                </div>
                <div>
                  <strong>Price Per Sqft:</strong> $469/sqft
                </div>
                <div>
                  <strong>MLS Number:</strong> 6777701
                </div>
              </div>
            </section>

            <section className="seller-pdetail-section location">
              <h3 className="seller-pdetail-info-header">
                {' '}
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Location
              </h3>
              <div className="seller-pdetail-info-grid">
                <div>
                  <strong>City:</strong> {propertyDetails.city}
                </div>
                <div>
                  <strong>Community:</strong> PARCEL 8A AT STONEGATE LOT 1-41 TR
                  A-D
                </div>
              </div>
            </section>

            <section className="seller-pdetail-section Amenities ">
              <h3 className="seller-pdetail-info-header">
                {' '}
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12.8638 3.49613C12.6846 3.18891 12.3557 3 12 3s-.6846.18891-.8638.49613l-3.49998 6c-.18042.30929-.1817.69147-.00336 1.00197S8.14193 11 8.5 11h7c.3581 0 .6888-.1914.8671-.5019.1784-.3105.1771-.69268-.0033-1.00197l-3.5-6ZM4 13c-.55228 0-1 .4477-1 1v6c0 .5523.44772 1 1 1h6c.5523 0 1-.4477 1-1v-6c0-.5523-.4477-1-1-1H4Zm12.5-1c-2.4853 0-4.5 2.0147-4.5 4.5s2.0147 4.5 4.5 4.5 4.5-2.0147 4.5-4.5-2.0147-4.5-4.5-4.5Z" />
                </svg>
                Amenities
              </h3>
              <div className="seller-pdetail-info-grid">
                <div>
                  <p style={{ marginLeft: '2px' }}>
                    <strong>Amenities:</strong>
                  </p>
                  <ul>
                    {propertyDetails.amenities?.map((amenity, index) => (
                      <li key={index}>{amenity}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>

            <section className="seller-pdetail-section Incentives">
              <h3 className="seller-pdetail-info-header">
                {' '}
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z" />
                </svg>
                Incentives
              </h3>
              <div className="seller-pdetail-info-grid">
                <div>
                  <p>
                    <strong>Home appliances,TV</strong>
                  </p>
                </div>
              </div>
            </section>
          </div>

          {/* <div className="seller-pdetail-section-5">
      <h2 style={{ fontWeight: "bold" }}>
        All New Homes in Wright Farms: Cottage Collection
      </h2>
      <p>Quick Move-in Homes ({sellerproperty.length})</p>
      <table className="seller-pdetail-table">
        <thead>
          <tr>
          <th>Count</th>
        <th>Project Name</th>
        <th>City</th>
        <th>Style</th>
        <th>Area (sqft)</th>
        <th>Model</th>
          </tr>
        </thead>
        <tbody>
          {sellerproperty.map((child, index) => (
                   <tr key={child._id}>
                     <td>{index + 1}</td>
                     <td>
                       <Link to={`/sellerproperties/${child._id}`}>
                         {child.projectname}
                       </Link>
                     </td>
                     <td>{child.city}</td>
                     <td>{child.housestyle}</td>
                     <td>{child.squarefeet}</td>
                     <td>{child.modelname}</td>
                   </tr>
                 ))}
        </tbody>
      </table>
    </div> */}

          <div className="builder-btn-div">
            <button className="builder-update-btn" onClick={handleNavigate}>
              Update
            </button>
            <button className="builder-delete-btn" onClick={handleDelete}>
              Delete
            </button>
          </div>
        </div>
      ) : (
        <p>Loading property details...</p>
      )}
    </>
  );
}

export default SellerPropertyDetail;
