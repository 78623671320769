import React, { useState, useEffect } from 'react';
import './savedproperties.css';
import Cookies from 'js-cookie';
import { FaTrash } from 'react-icons/fa'; // Import trash icon
import { customAxios } from '../../../../config/axios';

function SavedProperties() {
  const [savedBuilders, setSavedBuilders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSavedBuilders = async () => {
      try {
        const token = Cookies.get('token');
        const response = await customAxios.get('/agent/agent-getbuildersaved', {
          headers: { 'x-access-token': token },
        });

        if (response.data.status) {
          setSavedBuilders(response.data.data);
        } else {
          console.error(
            'Failed to fetch saved builders:',
            response.data.message
          );
        }
      } catch (error) {
        console.error('Error fetching saved builders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSavedBuilders();
  }, []);

  const handleRemoveBuilder = async (builderId) => {
    try {
      const token = Cookies.get('token');
      const response = await customAxios.delete(
        '/agent/agent-deletebuildersaved',
        {
          headers: { 'x-access-token': token },
          data: { id: builderId },
        }
      );

      if (response.data.status) {
        setSavedBuilders(
          savedBuilders.filter((builder) => builder._id !== builderId)
        );
      } else {
        console.error('Failed to remove builder:', response.data.message);
      }
    } catch (error) {
      console.error('Error removing builder:', error);
    }
  };

  return (
    <>
      <h1 style={{ marginLeft: '2rem', marginTop: '2rem', fontWeight: 'bold' }}>
        Saved Builders
      </h1>

      <div className="builder-flex-container">
        {loading ? (
          <p>Loading saved builders...</p>
        ) : savedBuilders.length === 0 ? (
          <p>No saved builders found.</p>
        ) : (
          savedBuilders.map((builder) => (
            <div key={builder._id} className="builder-detail-card">
              <span
                className="remove-icon"
                onClick={() => handleRemoveBuilder(builder._id)}
              >
                <FaTrash />
              </span>
              <img
                src="https://via.placeholder.com/150"
                alt={builder.name}
                className="dealer-image"
              />
              <h3>{builder.name}</h3>
              <p>Email: {builder.email}</p>
              <p>Phone: {builder.phone}</p>
              <p>Company: {builder.companyname}</p>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default SavedProperties;
