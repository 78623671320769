/** @format */

import styled from 'styled-components';

export const Conatainer = styled.div`
  width: min(90rem, 95%);
  margin: 3rem auto;

  text-align: center;
  .Content {
    h1 {
      color: #13131a;
      text-align: center;
      font-family: Tahoma (sans-serif);
      font-size: 3rem;
      font-size: clamp(1.5rem, 1rem + 2vw, 3rem);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: #13131a;
      text-align: center;
      font-family: Tahoma (sans-serif);
      font-size: 1.5rem;
      font-size: clamp(0.75rem, 1rem + 1vw, 1.5rem);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  .Card {
    text-align: center;
    border-radius: 3.125rem;
    background: #fff;
    box-shadow: 0px 0px 80px -30px rgba(4, 119, 255, 0.3);
    padding: 3rem 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      color: #000;
      text-align: center;
      font-family: Tahoma (sans-serif);
      font-size: 1.75rem;
      font-size: clamp(1rem, 1rem + 1vw, 1.75rem);
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    img {
      margin-bottom: 2rem;
    }
  }
  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`;
