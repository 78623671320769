import styled from 'styled-components';

/** @format */
import backgroundImage from './Images/Hero_new.jpg';

export const Container = styled.div`
  position: relative;

  .main {
    width: min(100rem, 98%);
    height: calc(90vh - 4rem);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.6rem;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center;
    background-attachment: cover;
    border-radius: 0.50rem;

   .leftSection {
  flex: 1;
  .hero-title {
    color: white;
    font-family: Tahoma, sans-serif;
    font-size: clamp(1.2rem, 1rem + 2vw, 4rem);
    font-style: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    font-weight: 700;
    line-height: normal;
    margin-left: 5rem;
  }
}


    
  @media screen and (max-width: 768px) {
    .main {
      flex-direction: column;
      gap: 1rem;
      height: calc(60vh - 3rem);
      .leftSection {
        .hero-title {
          margin-top: 8rem;
          margin-left: 1.5rem; 
          font-size: clamp(1rem, 0.8rem + 2vw, 2.5rem); 
        }
      }
      
      
  /* Small Screen Adjustments (Mobile Phones) */
  @media screen and (max-width: 560px) {
    .main {
      flex-direction: column-reverse;
      height: auto;
      align-items: center;

      .leftSection {
        .hero-title {
          font-size: 1.8rem; 
          text-align: center;
          margin-left: 0; 
          margin-top: 1rem;
        }
      }

      
`;

export const SerchContainer = styled.div`
  border-radius: 25rem;
  max-width: 32rem;
  background-color: white;
  max-height: 5rem;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5rem;

  .icon {
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .search-input {
    flex: 1;
    border: none;
    outline: none;
    margin-left: 0.75rem;
  }

  .get-started-btn {
    border-radius: 25rem;
    max-height: 5rem;
    height: 5rem;
    width: 10rem;
    font-family: Tahoma (sans-serif);
    font-weight: 500;
    background-color: black;
    padding: 1.2rem;
    color: white;
    transition: border 0.3s ease;
  }

  .get-started-btn:hover {
    border: 1px solid #0061df;
  }

  @media screen and (max-width: 768px) {
    margin-left: 5rem;
    max-width: 60%;
    margin-bottom: 1rem;

    .get-started-btn {
      padding: 0.8rem 1rem;
      font-size: 0.775rem;
      width: 50%;
    }
  }

  /* Mobile Adjustments */
  @media screen and (max-width: 560px) {
    margin-left: 4rem;
    max-width: 70%;
    max-height: 5rem;
    margin-bottom: 1rem;

    .get-started-btn {
      padding: 0.5rem 0.2rem;
      font-size: 0.675rem;
      width: 70%;
    }
  }
`;

export const Vectors = styled.img`
  position: absolute;
  bottom: 5rem;
  width: 100%;
  z-index: -100;
`;
