/** @format */

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: min(80rem, 95%);
  margin: 0 auto;
  p {
    margin: 0;
  }
  .Content {
    h1 {
      color: #13131a;
      text-align: center;
      font-family: Tahoma (sans-serif);
      font-size: 3rem;
      font-size: clamp(1.5rem, 1rem + 2vw, 3rem);
      font-weight: 700;
    }
    p {
      color: #13131a;
      text-align: center;
      font-family: Tahoma (sans-serif);
      font-size: 1.5rem;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
`;

export const PropertyCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 5rem;
  @media screen and (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 708px) {
    grid-template-columns: 1fr;
  }
`;

export const Button = styled(Link)`
  margin: 2rem auto;
  display: block;
  border-radius: 0.9375rem;
  background: #0477ff;
  box-shadow: 2px 2px 4px 0px rgba(4, 119, 255, 0.3);
  width: fit-content;
  text-decoration: none;
  padding: 1.25rem 2.5rem;
  color: #fff;
  font-family: Tahoma (sans-serif);
  font-size: 1.125rem;
  font-size: clamp(0.75rem, 1rem + 1vw, 1.125rem);
  font-weight: 600;
`;
