import React from 'react';
import './sellerinfo.css';

function Sellerinfo() {
  const seller = [
    {
      id: 1,
      name: 'John Doe',
      email: 'john@example.com',
      address: '123 Main St, NY',
      image:
        'https://images.pexels.com/photos/10542445/pexels-photo-10542445.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
      id: 2,
      name: 'Jane Smith',
      email: 'jane@example.com',
      address: '456 Oak St, CA',
      image:
        'https://i.pinimg.com/236x/34/df/e8/34dfe8242ca6d0b663de5d4098d39f47.jpg',
    },
    {
      id: 3,
      name: 'Mike Johnson',
      email: 'mike@example.com',
      address: '789 Pine St, TX',
      image:
        'https://images.pexels.com/photos/10542445/pexels-photo-10542445.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
      id: 4,
      name: 'Emily Davis',
      email: 'emily@example.com',
      address: '101 Maple St, FL',
      image:
        'https://i.pinimg.com/236x/34/df/e8/34dfe8242ca6d0b663de5d4098d39f47.jpg',
    },
    {
      id: 5,
      name: 'Robert Brown',
      email: 'robert@example.com',
      address: '202 Birch St, IL',
      image: 'https://via.placeholder.com/150',
    },
    {
      id: 6,
      name: 'Laura Wilson',
      email: 'laura@example.com',
      address: '303 Cedar St, WA',
      image:
        'https://images.pexels.com/photos/10542445/pexels-photo-10542445.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
    {
      id: 7,
      name: 'Laura Wilson',
      email: 'laura@example.com',
      address: '303 Cedar St, WA',
      image: 'https://via.placeholder.com/150',
    },
    {
      id: 6,
      name: 'Laura Wilson',
      email: 'laura@example.com',
      address: '303 Cedar St, WA',
      image:
        'https://images.pexels.com/photos/10542445/pexels-photo-10542445.jpeg?auto=compress&cs=tinysrgb&w=600',
    },
  ];
  return (
    <div className="card-container">
      <h1>Seller Info</h1>
      <div className="col-sm-6 col-lg-3 mt-4">
        <div className="dealer-container">
          {seller.map((seller) => (
            <div key={seller.id} className="dealer-card">
              <img
                src={seller.image}
                alt={seller.name}
                className="dealer-image"
              />
              <h3>{seller.name}</h3>
              <p>Email: {seller.email}</p>
              <p>Address: {seller.address}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sellerinfo;
