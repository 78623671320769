import styled from 'styled-components';

export const CustomBarContainer = styled.div`
  height: 0.5rem;
  border-radius: 6.25rem;
  background: #d9d9d9;
  margin-top: 1rem;
`;
export const Progress = styled.div`
  height: inherit;
  width: ${(props) => props.PWidth};
  /* margin-left:${(props) => props.PWidth}; ; */
  background: #0061df;
  border-radius: 6.25rem;
  transition: width 1s;
`;
