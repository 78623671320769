import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Footer from '../Footer/Footer.component';
import {
  Container,
  Login,
  Logo,
  MobileLinks,
  NavLink,
  Register,
} from './Navbar.style';
import { toast } from 'react-toastify';

export default function Navbar() {
  const [hamIcon, setHamIcon] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = () => {
      const token = Cookies.get('token');
      const role = Cookies.get('userRole');

      if (token) {
        setIsAuthenticated(true);
        setUserRole(role || null);
      } else {
        setIsAuthenticated(false);
        setUserRole(null);
      }
    };

    checkAuth();
  }, [location.pathname]);

  const toggleNavbar = () => {
    setHamIcon((prev) => !prev);
  };

  const handleLogout = () => {
    Cookies.remove('token');
    Cookies.remove('userRole');
    setIsAuthenticated(false);
    setUserRole(null);
    toast.error('Logged Out');
    navigate('/');
  };

  const handleProfileClick = () => {
    if (userRole) {
      navigate(`/dashboard/${userRole.toLowerCase()}`);
    }
  };

  return (
    <>
      <Container>
        <div className="main">
          <div className="Section1">
            <Logo to="/">
              <h4 style={{ color: '#0061df', fontWeight: 'bold' }}>
                BuilderMatch
              </h4>
            </Logo>

            <div
              className="HamBurggerMenu"
              onClick={toggleNavbar}
              style={{ position: 'absolute', left: '90%', zIndex: 100000 }}
            >
              {hamIcon ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                >
                  <path
                    d="M5 5L29 29M5 29L29 5"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="35"
                  viewBox="0 0 34 35"
                  fill="none"
                >
                  <path
                    d="M5 10h24M5 17h24M5 24h24"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </div>
          </div>

          <div className="Section2">
            <NavLink Check={location.pathname === '/hot-deals'} to="/hot-deals">
              Hot deals
            </NavLink>
            <NavLink Check={location.pathname === '/builders'} to="/builders">
              Builders
            </NavLink>
            <NavLink Check={location.pathname === '/buy'} to="/buy">
              Buy
            </NavLink>
            <NavLink Check={location.pathname === '/search'} to="/search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </NavLink>
            <NavLink
              Check={location.pathname === '/map-search'}
              to="/map-search"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 10c0 6-9 12-9 12s-9-6-9-12a9 9 0 0 1 18 0z"></path>
                <circle cx="12" cy="10" r="3"></circle>
              </svg>
            </NavLink>
          </div>

          <div className="Section3">
            {isAuthenticated ? (
              <>
                <Login
                  as="button"
                  style={{ border: 'none', backgroundColor: 'white' }}
                  onClick={handleProfileClick}
                >
                  Profile
                </Login>
                <Login
                  as="button"
                  style={{ border: 'none', backgroundColor: 'white' }}
                  onClick={handleLogout}
                >
                  Logout
                </Login>
              </>
            ) : (
              <>
                <Login to="/login">Login</Login>
                <Register to="/signup">Sign up</Register>
              </>
            )}
          </div>
        </div>

        {/* Mobile Navigation Links */}
        <MobileLinks open={hamIcon}>
          <div>
            <NavLink to="/" onClick={toggleNavbar}>
              Home
            </NavLink>
            <NavLink to="/hot-deals" onClick={toggleNavbar}>
              Hot deals
            </NavLink>
            <NavLink to="/builders" onClick={toggleNavbar}>
              Builders
            </NavLink>
            <NavLink to="/buy" onClick={toggleNavbar}>
              Buy
            </NavLink>
            <NavLink to="/search" onClick={toggleNavbar}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>{' '}
              Search
            </NavLink>
            <NavLink to="/map-search" onClick={toggleNavbar}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 10c0 6-9 12-9 12s-9-6-9-12a9 9 0 0 1 18 0z"></path>
                <circle cx="12" cy="10" r="3"></circle>
              </svg>{' '}
              Map Search
            </NavLink>

            {isAuthenticated ? (
              <>
                <NavLink as="button" onClick={handleProfileClick}>
                  Profile
                </NavLink>
                <NavLink as="button" onClick={handleLogout}>
                  Logout
                </NavLink>
              </>
            ) : (
              <>
                <NavLink to="/login" onClick={toggleNavbar}>
                  Login
                </NavLink>
                <NavLink to="/signup" onClick={toggleNavbar}>
                  Register
                </NavLink>
              </>
            )}
          </div>
        </MobileLinks>
      </Container>

      <Outlet />
      <Footer />
    </>
  );
}
