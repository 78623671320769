/** @format */

import React, { useState } from 'react';
import { FormContainer } from './AppointmentForm.style';
import Loader from '../Loader/Loader.components';
import axios from 'axios';
export default function AppointmentForm({ Data }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    date: '',
    time: '',
    message: `I would like an appointment scheduled on the above mentioned date and time to know more about this property at ${
      Data?.property.relation === null ? '' : Data?.property.relation
    }`,
    is_appointment: false,
  });
  console.log(formData.is_appointment);
  console.log(formData);
  const [loader, setloader] = useState('form');
  const ScheduleAppointment = async (e) => {
    e.preventDefault();
    setloader('loader');
    if (
      formData.name !== '' &&
      formData.email !== '' &&
      formData.contact !== '' &&
      formData.date !== '' &&
      formData.time !== '' &&
      formData.message !== ''
    ) {
      const Data = new FormData();
      const currentUrl = window.location.href;
      Data.append('time', formData.time);
      Data.append('date', formData.date);
      Data.append('name', formData.name);
      Data.append('email', formData.email);
      Data.append('phone', formData.contact);
      Data.append('property_url', currentUrl);
      Data.append('message', formData.message);
      Data.append('source', 'buildermatch.ca');
      Data.append(
        'is_appoinment',
        formData.is_appointment === true ? '0' : '1'
      );
      try {
        await axios.post('https://dev.redbuyers.com/api/user-lead', Data);
        console.log('submited');
        setloader('submitted');
      } catch (err) {
        console.log(err);
        setloader('error');
      }
    }
  };

  return (
    <FormContainer
      onClick={(event) => event.stopPropagation()}
      onSubmit={ScheduleAppointment}
    >
      {loader === 'form' && (
        <>
          <h3>
            Interested? <br />
            Schedule Appointment <br /> Now.
          </h3>
          <input
            type="text"
            required
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder="Name"
            className="Name Input"
          />
          <input
            type="text"
            maxLength="10"
            required
            value={formData.contact}
            onChange={(e) =>
              setFormData({ ...formData, contact: e.target.value })
            }
            placeholder="Contact"
            className="Contact Input"
          />
          <input
            type="email"
            required
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            placeholder="Email"
            className="Email Input"
          />
          <input
            type="date"
            required
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
            placeholder="Date"
            className="Date Input"
          />
          <input
            type="time"
            required
            value={formData.time}
            onChange={(e) => setFormData({ ...formData, time: e.target.value })}
            className="Time Input"
          />
          <textarea
            className="TextArea Input"
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
          ></textarea>
          <div className="CheckBox">
            <input
              type="checkbox"
              checked={formData.is_appointment}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_appointment: e.target.checked,
                })
              }
            />
            <p>
              Please send me information and include price lists and floor
              plans.
            </p>
          </div>
          <button>Schedule Appointment</button>
        </>
      )}
      {loader === 'loader' && <Loader />}
      {loader === 'submitted' && <h1>Submitted</h1>}
      {loader === 'error' && <h1>something went wrong</h1>}
    </FormContainer>
  );
}
