export function setImageBanner(Data, setCoverPhotos) {
  const homeType = Data?.property?.home_type;
  const planProperties =
    Data?.property?.plan_properties[0]?.property_cover_image;
  const elevationImage =
    Data?.property?.property_details?.property_elevation_image;
  const properties = ['Detached', 'Semi-detached', 'Town House'];

  if (!properties.includes(homeType) && planProperties?.length) {
    setCoverPhotos(planProperties);
  } else if (properties.includes(homeType) && elevationImage?.length) {
    console.log(elevationImage);
    setCoverPhotos(elevationImage);
  } else {
    setCoverPhotos([{ file_url: '/assets/images/no-image-available.png' }]);
  }
}
