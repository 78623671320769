import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd'; // Import react-dnd hooks
import { HTML5Backend } from 'react-dnd-html5-backend';
import { customAxios } from '../../config/axios';
import { useParams } from 'react-router-dom';
import '../Child Form/chilform.css';

function ChildForm() {
  // State for selected images
  const [modelImage, setModelImage] = useState([]);
  const [floorPlanImage, setFloorPlanImage] = useState([]);
  const [childForms, setChildForms] = useState([]);
  const { parentId } = useParams();
  const [formData, setFormData] = useState({
    modelname: '',
    housetype: 'Detached',
    housestyle: 'Multi Story',
    price: '',
    bed: '0',
    bath: '0',
    squarefeet: '',
    garage: '0',
    lotsize: '',
    projectname: 'testing the project',
    modeldescription: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { modeldescription, ...values } = formData;
    const updatedFormData = {
      ...values,
      unidescription: modeldescription,
      unitImages: modelImage.map((data) => data.name),
      floorPlanImage: floorPlanImage.map((data) => data.name),
    };

    const DataConvert = new FormData();
    DataConvert.append('modelname', updatedFormData.modelname);
    DataConvert.append('housetype', updatedFormData.housetype);
    DataConvert.append('housestyle', updatedFormData.housestyle);

    DataConvert.append('bed', updatedFormData.bed);
    DataConvert.append('bath', updatedFormData.bath);
    DataConvert.append('squarefeet', updatedFormData.squarefeet);
    DataConvert.append('garage', updatedFormData.garage);
    DataConvert.append('lotsize', updatedFormData.lotsize);
    DataConvert.append('projectname', updatedFormData.projectname);
    DataConvert.append('modeldescription', updatedFormData.modeldescription);
    DataConvert.append('parentId', parentId);
    DataConvert.append('modeldescription', updatedFormData.modeldescription);
    DataConvert.append('price', updatedFormData.price);
    floorPlanImage.forEach((image) => {
      DataConvert.append('floorplanImages', image);
    });
    modelImage.forEach((image) => {
      DataConvert.append('unitImages', image);
    });
    try {
      const { data } = await customAxios.post(
        `/builderchild/add-builderchild/${parentId}`,
        DataConvert,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      // Push the form data into childForms

      if (data.status === true) {
        setChildForms((prevForms) => [
          ...prevForms,
          {
            ...formData,
            modelImage,
            floorPlanImage,
          },
        ]);

        // Reset the form after submitting
        setFormData({
          modelname: '',
          housetype: 'Detached',
          housestyle: 'Multi Story',
          price: '',
          bed: '0',
          bath: '0',
          squarefeet: '',
          garage: '0',
          lotsize: '',
          modeldescription: '',
          projectname: 'testing the project',
        });
      }
      setModelImage([]);
      setFloorPlanImage([]);
    } catch (error) {
      throw new error('Error in submitting form');
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle image changes
  const handleModelImageChange = (e) => {
    const files = Array.from(e.target.files);
    setModelImage((prevImages) => [...prevImages, ...files]);
  };

  const handleFloorPlanImageChange = (e) => {
    const files = Array.from(e.target.files);
    console.log(files);
    setFloorPlanImage((prevImages) => [...prevImages, ...files]);
  };

  // Helper function to generate image preview
  const renderImagePreview = (images, setImages) => {
    return images.map((image, index) => (
      <ImageItem
        key={index}
        index={index}
        image={image}
        images={images}
        setImages={setImages}
      />
    ));
  };

  // Function to delete a specific form based on its index
  const handleDeleteForm = (indexToDelete) => {
    setChildForms((prevForms) =>
      prevForms.filter((_, index) => index !== indexToDelete)
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="child-background">
        <div className="child-form-container">
          <h1 className="childform-h1">Child Property</h1>
          <form className="child-form" onSubmit={handleSubmit}>
            <div className="form-row">
              {/* Form Inputs */}
              <div className="form-group">
                <label htmlFor="model-name" className="chilform-label">
                  Model Name:
                </label>
                <input
                  type="text"
                  className="input-form-child"
                  id="model-name"
                  name="modelname"
                  value={formData.modelname}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="house-type" className="chilform-label">
                  House Type:
                </label>
                <select
                  id="house-type"
                  className="select-form-child"
                  name="housetype"
                  value={formData.housetype}
                  onChange={handleInputChange}
                >
                  <option value="Detached">Detached</option>
                  <option value="Semi-detached">Semi-detached</option>
                  <option value="Twon-House">Town House</option>
                  <option value="Condo-Twonhouse">Condo-townhouse</option>
                  <option value="Condo-Apartment">Condo apartment</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="house-style" className="chilform-label">
                  House Style:
                </label>
                <select
                  id="house-style"
                  className="select-form-child"
                  name="housestyle"
                  value={formData.housestyle}
                  onChange={handleInputChange}
                >
                  <option value="Multi Story">Multi Story</option>
                  <option value="Three Story">3 Story</option>
                  <option value="Two Story">2 Story</option>
                  <option value="Bungalow">Bungalow</option>
                  <option value="Split Level">Split Level</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="price" className="chilform-label">
                  Price:
                </label>
                <input
                  type="number"
                  className="input-form-child"
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="bed" className="chilform-label">
                  Bed:
                </label>
                <select
                  id="bed"
                  className="select-form-child"
                  name="bed"
                  value={formData.bed}
                  onChange={handleInputChange}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6+</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="bath" className="chilform-label">
                  Bath:
                </label>
                <select
                  id="bath"
                  className="select-form-child"
                  name="bath"
                  value={formData.bath}
                  onChange={handleInputChange}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6+</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="square-feet" className="chilform-label">
                  Square Feet:
                </label>
                <input
                  type="number"
                  className="input-form-child"
                  id="square-feet"
                  name="squarefeet"
                  value={formData.squarefeet}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="garage" className="chilform-label">
                  Garage:
                </label>
                <select
                  id="garage"
                  className="select-form-child"
                  name="garage"
                  value={formData.garage}
                  onChange={handleInputChange}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3+</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="lot-size" className="chilform-label">
                  Lot Size (Freehold only):
                </label>
                <input
                  type="number"
                  className="input-form-child"
                  id="lot-size"
                  name="lotsize"
                  value={formData.lotsize}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="model-description" className="chilform-label">
                  Model or Unit Description:
                </label>
                <textarea
                  id="model-description"
                  className="textarea-form-child"
                  name="modeldescription"
                  value={formData.modeldescription}
                  onChange={handleInputChange}
                ></textarea>
              </div>

              <div className="form-group">
                <label htmlFor="model-image" className="chilform-label">
                  Model or Unit Image:
                </label>
                <input
                  type="file"
                  accept="image/*"
                  className="input-form-child"
                  style={{ backgroundColor: 'white' }}
                  id="model-image"
                  name="model-image"
                  multiple
                  onChange={handleModelImageChange}
                />
                <div className="image-preview">
                  {renderImagePreview(modelImage, setModelImage)}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="floor-plan-image" className="chilform-label">
                  Floor Plan Image:
                </label>
                <input
                  type="file"
                  accept="image/*"
                  className="input-form-child"
                  style={{ backgroundColor: 'white' }}
                  id="floor-plan-image"
                  name="floor-plan-image"
                  multiple
                  onChange={handleFloorPlanImageChange}
                />
                <div className="image-preview">
                  {renderImagePreview(floorPlanImage, setFloorPlanImage)}
                </div>
              </div>

              <button type="submit" className="button-child">
                Submit
              </button>
            </div>
          </form>

          <div className="col-sm-6 col-lg-4 mt-3">
            <div className="form-preview-container">
              {childForms.map((childForm, index) => (
                <div className="form-preview-card">
                  <h2 className="form-preview-card-title">Form Previews</h2>

                  <div className="form-preview-card-content">
                    <div key={index} className="form-preview-item">
                      <h3>Form {index + 1} Preview</h3>

                      {/* Form Details */}
                      <p>
                        <strong>Model Name:</strong> {childForm.modelName}
                      </p>
                      <p>
                        <strong>House Type:</strong> {childForm.houseType}
                      </p>
                      <p>
                        <strong>House Style:</strong> {childForm.houseStyle}
                      </p>
                      <p>
                        <strong>Price:</strong> {childForm.price}
                      </p>
                      <p>
                        <strong>Bed:</strong> {childForm.bed}
                      </p>
                      <p>
                        <strong>Bath:</strong> {childForm.bath}
                      </p>
                      <p>
                        <strong>Square Feet:</strong> {childForm.squareFeet}
                      </p>
                      <p>
                        <strong>Garage:</strong> {childForm.garage}
                      </p>
                      <p>
                        <strong>Lot Size:</strong> {childForm.lotSize}
                      </p>
                      <p>
                        <strong>Model or Unit Description:</strong>{' '}
                        {childForm.modelDescription}
                      </p>

                      {/* Model Image Preview */}
                      <div className="form-preview-image-container">
                        <strong>Model Image:</strong>
                        <div className="form-image-preview">
                          {childForm.modelImage.map((image, idx) => (
                            <div key={idx}>
                              <img
                                src={URL.createObjectURL(image)}
                                alt={`Model Preview ${idx}`}
                                className="image-preview-thumbnail"
                              />
                              {/* <div>{image.name}</div> */}
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Floor Plan Image Preview */}
                      <div className="form-preview-image-container">
                        <strong>Floor Plan Image:</strong>
                        <div className="form-image-preview">
                          {childForm.floorPlanImage.map((image, idx) => (
                            <div key={idx}>
                              <img
                                src={URL.createObjectURL(image)}
                                alt={`Floor Plan Preview ${idx}`}
                                className="image-preview-thumbnail"
                              />
                              {/* <div>{image.name}</div> */}
                            </div>
                          ))}
                        </div>
                        <button
                          className="form-prewiew-delete"
                          onClick={() => handleDeleteForm(index)}
                        >
                          Delete form
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

// ImageItem Component for draggable items
const ImageItem = ({ index, image, images, setImages }) => {
  const [, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    hover: (item) => {
      if (item.index !== index) {
        const newImages = [...images];
        newImages.splice(index, 0, newImages.splice(item.index, 1)[0]);
        setImages(newImages);
        item.index = index;
      }
    },
  });

  return (
    <div ref={(node) => drag(drop(node))} className="image-preview-item">
      <img
        src={URL.createObjectURL(image)}
        alt={`Preview ${index}`}
        className="image-preview-thumbnail"
      />
      <button
        onClick={() => removeImage(index, images, setImages)}
        className="remove-image-button"
      >
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
          />
        </svg>
      </button>
    </div>
  );
};

// Define the types for drag items
export const ItemTypes = {
  IMAGE: 'image',
};

const removeImage = (index, images, setImages) => {
  const newImages = [...images];
  newImages.splice(index, 1);
  setImages(newImages);
};

export default ChildForm;
